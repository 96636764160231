import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import Footer from '../../components/Footer/FooterNew';
import css from './LandingPage.css';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import HeroSection from './sections/HeroSection/HeroSection';
import NewExperiences from './sections/NewExperiences/NewExperiences';
import ThisIsSocialbnb from './sections/ThisIsSocialbnb/ThisIsSocialbnb';
import YourMomentSection from './sections/YourMomentSection/YourMomentSection';
import WhySocialbnbSection from './sections/WhySocialbnbSection/WhySocialbnbSection';
import QuickMenu from './sections/QuickMenu/QuickMenu';
import YourMomentSectionSkeleton from './sections/Skeletons/YourMomentSectionSkeleton';
import WhySocialbnbSectionSkeleton from './sections/Skeletons/WhySocialbnbSectionSkeleton';
import NewExperiencesSkeleton from './sections/Skeletons/NewExperiencesSkeleton';
import ThisIsSocialbnbSkeleton from './sections/Skeletons/ThisIsSocialbnbSkeleton';
import SectionLoader from './sections/Skeletons/SectionLoader';

const LandingPage = () => {
  const topbarRef = useRef(null);
  const [userEmail, setUserEmail] = useState('false');
  const [position, setPosition] = useState(0);

  const location = useLocation();
  const intl = useIntl();

  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category') || 'experiences';
  const type = queryParams.get('type') || 'default';

  const generateSEOData = (intl, category = 'experiences', type = 'default') => {
    const getMessageId = field => `CustomLandingPage.${category}.${type || 'default'}.${field}`;

    return {
      title: intl.formatMessage({ id: getMessageId('title') }),
      description: intl.formatMessage({ id: getMessageId('desc') }),
      h1: getMessageId('h1'),
      h2: intl.formatMessage({ id: getMessageId('h2') }),
    };
  };

  const seoData = generateSEOData(intl, category, type);

  const getRandomVersion = () => {
    return Math.random() < 0.5 ? 'versionA' : 'versionB';
  };

  const setLocalStorageWithExpiry = (key, value, ttl) => {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  const getLocalStorageWithExpiry = key => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window?.document) {
      document?.body?.scrollTo({
        top: 0,
      });

      const touchMoveEvent = 'ontouchmove' in document?.documentElement ? 'touchmove' : null;
      const wheelEvent = 'onwheel' in document?.createElement('div') ? 'wheel' : 'mousewheel';

      const debouncedChangeCss = debounce(changeCss, 100);
      window.addEventListener(wheelEvent, debouncedChangeCss, false);
      window.addEventListener(touchMoveEvent, debouncedChangeCss, { passive: true });

      const existingVersion = getLocalStorageWithExpiry('userVersion');
      if (!existingVersion) {
        const newVersion = getRandomVersion();
        setLocalStorageWithExpiry('userVersion', newVersion, 30 * 24 * 60 * 60 * 1000); // 30days expiration
      }

      return () => {
        window.removeEventListener(wheelEvent, debouncedChangeCss, false);
        window.removeEventListener(touchMoveEvent, debouncedChangeCss, { passive: true });
      };
    }
  }, []);

  const changeCss = useCallback(() => {
    if (typeof window !== 'undefined' && window?.document) {
      setPosition(document?.body?.scrollTop || 0);
    }
  }, []);

  const pageContent = (
    <div className="w-full">
      <div data-elb="section" data-section-index="0">
        <HeroSection h1={seoData?.h1} h2={seoData?.h2} />
      </div>

      <div data-elb="section" data-section-index="1">
        <SectionLoader skeletonComponent={() => <ThisIsSocialbnbSkeleton />}>
          <ThisIsSocialbnb />
        </SectionLoader>
      </div>

      <div
        data-elb="section"
        data-section-index="2"
        data-elb-section="name:yourmomentsection;position:4"
      >
        <SectionLoader skeletonComponent={() => <YourMomentSectionSkeleton />}>
          <YourMomentSection />
        </SectionLoader>
      </div>

      <div
        data-elb="section"
        data-section-index="3"
        data-elb-section="name:newexperiences;position:4"
      >
        <SectionLoader skeletonComponent={() => <NewExperiencesSkeleton />}>
          <NewExperiences />
        </SectionLoader>
      </div>

      <div data-elb="section" data-section-index="4">
        <SectionLoader skeletonComponent={() => <WhySocialbnbSectionSkeleton />}>
          <WhySocialbnbSection />
        </SectionLoader>
      </div>

      <QuickMenu />
    </div>
  );

  return (
    <Page
      title={seoData?.title}
      keywords={intl.formatMessage({ id: 'CustomLandingPage.keywords' })}
      description={seoData?.description}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'socialbnb',
        url: `https://socialbnb.org/${intl?.locale || 'en'}`,
      }}
      noChildrenMargin={true}
    >
      <LayoutSingleColumn
        mainColumnClassName={css.layoutWrapperMain}
        topbar={
          <div
            id="topbar"
            ref={topbarRef}
            className={css.topbarScrolled}
            style={{ position: 'fixed', width: '100vw', top: 0 }}
            data-elbaction={`wait(10):isAuthenticated:${userEmail}`}
          >
            <TopbarContainer setUserEmail={setUserEmail} />
          </div>
        }
        footer={
          <div data-elb="section" data-elbaction="visible:vis footer">
            <Footer />
          </div>
        }
      >
        <div id="my-scrollbar" className={css.Wrapper}>
          {pageContent}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

export default LandingPage;
