import React from 'react';
import css from './generalInfo.module.css';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

const GeneralInfoSetion = () => {
  const { lang } = useParams();
  const foreignMinistryLink =
    lang === 'en'
      ? 'https://www.auswaertiges-amt.de/en'
      : 'https://www.auswaertiges-amt.de/de/ReiseUndSicherheit/reise-und-sicherheitshinweise';
  return (
    <div>
      <h2>
        <FormattedMessage id="listingPage.generalInfo.generalInformation" />
      </h2>
      <ul className={css.list}>
        <li className={css.listItem}>
          <FormattedMessage id="listingPage.generalInfo.limitedMobility" />
          <ul className={css.list}>
            <li className={css.listItem}>
              <FormattedMessage
                id="listingPage.generalInfo.accessibilityNotice"
                values={{
                  contactLink: (
                    <a
                      href={`https://www.socialbnb.org/${lang}/contact`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {lang === 'de' ? 'bei uns.' : 'contact us'}
                    </a>
                  ),
                }}
              />
            </li>
          </ul>
        </li>
        <li className={css.listItem}>
          <FormattedMessage id="listingPage.generalInfo.visaRequirements" />
          <ul className={css.list}>
            <li className={css.listItem}>
              <FormattedMessage
                id="listingPage.generalInfo.visaRequirementsNotice"
                values={{
                  foreignMinistryLink: (
                    <a href={foreignMinistryLink} target="_blank" rel="noopener noreferrer">
                      {lang === 'de' ? 'Auswärtigen Amt' : 'Federal Foreign Office'}
                    </a>
                  ),
                  contactLink: (
                    <a
                      href={`https://www.socialbnb.org/${lang}/contact`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {lang === 'de' ? 'bei uns.' : 'contact us'}
                    </a>
                  ),
                }}
              />{' '}
            </li>
          </ul>
        </li>
        <li className={css.listItem}>
          <FormattedMessage id="listingPage.generalInfo.travelInsuranceRecommendation" />
        </li>
      </ul>
    </div>
  );
};

export default GeneralInfoSetion;
