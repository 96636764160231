import italy1 from '../../assets/../../../../assets/impactAccomodationsPics/italien/1.webp';
import italy2 from '../../assets/../../../../assets/impactAccomodationsPics/italien/2.webp';
import italy3 from '../../assets/../../../../assets/impactAccomodationsPics/italien/3.webp';
import italy4 from '../../assets/../../../../assets/impactAccomodationsPics/italien/4.webp';
import italy5 from '../../assets/../../../../assets/impactAccomodationsPics/italien/5.webp';
import italy6 from '../../assets/../../../../assets/impactAccomodationsPics/italien/6.webp';
import italy7 from '../../assets/../../../../assets/impactAccomodationsPics/italien/7.webp';
import italy8 from '../../assets/../../../../assets/impactAccomodationsPics/italien/8.webp';

export const italyListings = [
  {
    link:
      '/l/bioagriturismo-podere-montisi-entire-accomodation/63eabf21-34e1-4e52-bc90-3e812f2aee00',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'bioagriturismo-podere-montisi-entire-accomodation',
      id: '63eabf21-34e1-4e52-bc90-3e812f2aee00',
    },
    name: 'Luxury Eco Farmhouse',
    name_de: 'Bioagriturismo Italien',
    price: ' 120€',
    location: 'Calenzano, Italy',
    projectType: ['nature', 'education'],
    image: italy1,
    finalReviews: '4.9',
    totalFinalReviews: 4.9,
  },
  {
    link: '/l/orto-dell-idro-doublebedroom/6233ba15-0b00-42a1-9992-d46b03f5196d',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'orto-dell-idro-doublebedroom',
      id: '6233ba15-0b00-42a1-9992-d46b03f5196d',
    },
    name: 'Stone House on Yoga Farm',
    name_de: 'Steinhaus auf Yoga-Farm',
    price: ' 120€',
    location: 'Otranto, Italy',
    projectType: ['nature', 'sports'],
    image: italy2,
    finalReviews: '4.1',
    totalFinalReviews: 4.1,
  },
  {
    link:
      '/l/borgo-del-ginepro-apartment-mandorlo-entire-accomodation/65b260e0-46a3-40ba-9f84-c52fcadede57',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'borgo-del-ginepro-apartment-mandorlo-entire-accomodation',
      id: '65b260e0-46a3-40ba-9f84-c52fcadede57',
    },
    name: 'Apartment on Eco Farm',
    name_de: 'Apartment auf Eco-Farm',
    price: ' 108€',
    location: 'Castiglione Messer R., Italy',
    projectType: ['nature'],
    image: italy3,
    finalReviews: '4.9',
    totalFinalReviews: 4.9,
  },
  {
    link:
      '/l/ospitalit-rurale-casale-il-sughero-entire-accomodation/63e10db5-5aec-454c-a271-60a52e044141',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ospitalit-rurale-casale-il-sughero-entire-accomodation',
      id: '63e10db5-5aec-454c-a271-60a52e044141',
    },
    name: 'Cabin with Sea View',
    name_de: 'Hütte mit Meerblick',
    price: ' 60€',
    location: 'Vibonati, Iitaly',
    projectType: ['nature', 'sports'],
    image: italy4,
    finalReviews: '5',
    totalFinalReviews: 5,
  },
  {
    link: '/l/casa-in-mezzo-zonca-entire-accomodation/67054f87-f314-4ca0-ac1f-d7e8449ab102',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'casa-in-mezzo-zonca-entire-accomodation',
      id: '67054f87-f314-4ca0-ac1f-d7e8449ab102',
    },
    name: 'Stone House in Eco-Village',
    name_de: 'Steinhaus im Eco-Dorf',
    price: ' 75€',
    location: 'Montescheno, Iitaly',
    projectType: ['nature', 'education'],
    image: italy5,
    finalReviews: '5',
    totalFinalReviews: 5,
  },
  {
    link: '/l/masseria-agriturismo-moschella-doublebedroom/624e9b3e-8ff3-40d4-bd7b-de7c590856d1',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'masseria-agriturismo-moschella-doublebedroom',
      id: '624e9b3e-8ff3-40d4-bd7b-de7c590856d1',
    },
    name: 'Organic Farmhouse in Puglia',
    name_de: 'Bio-Bauernhof in Apulien',
    price: ' 90€',
    location: 'Cerignola, Iitaly',
    projectType: ['nature', 'animal'],
    image: italy6,
    finalReviews: '4.9',
    totalFinalReviews: 4.9,
  },
  {
    link:
      '/l/comeback-agriturismo-family-room-entire-accomodation/64d8879e-75c2-430f-a98c-c3ae632992e9',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'comeback-agriturismo-family-room-entire-accomodation',
      id: '64d8879e-75c2-430f-a98c-c3ae632992e9',
    },
    name: 'Family Room in Eco Farm',
    name_de: 'Familienzimmer im Eco-Hof',
    price: ' 140€',
    location: 'Stezzano, Italy',
    projectType: ['nature', 'education'],
    image: italy7,
    finalReviews: '4.8',
    totalFinalReviews: 4.8,
  },
  {
    link: '/l/a-locanda-t-marchese-doublebedroom/6527b6a3-2846-4750-8f1b-b3e0ddcb3009',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'a-locanda-t-marchese-doublebedroom',
      id: '6527b6a3-2846-4750-8f1b-b3e0ddcb3009',
    },
    name: 'Double Room in Eco Farm',
    name_de: 'Doppelzimmer im Eco-Hof',
    price: ' 94€',
    location: 'Matino, Iitaly',
    projectType: ['nature', 'education'],
    image: italy8,
    finalReviews: '4.4',
    totalFinalReviews: 4.4,
  },
];
