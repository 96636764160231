import React, { useEffect, useState, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import css from './Header.module.css';
import cover from './assets/cover.webp';
import { TopBarSearch } from '../../Navbar/helpers/topBarSearch/topBarSearch';
import { useHistory } from 'react-router-dom';

export const FooterHeader = memo(() => {
  const history = useHistory();
  const [location, setLocation] = useState(null);
  const lang = useIntl().locale.trim();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setLocation(window.location);
    }
  }, []);

  if (!location) return null;

  return (
    <div
      className={css.wrapper}
      data-elb="section"
      data-elb-section="name:footerButtom;position:12"
      data-elbaction="visible:section lp-footer-search"
    >
      <div className={css.titleWrapper}>
        <div className={css.headerText}>
          <FormattedMessage id="footer.header.title" />
        </div>
        <div className={css.searchBar} data-elbaction={'click:click lp-footer-search'}>
          <TopBarSearch
            history={history}
            location={location}
            currentSearchParams={{}}
            language={lang}
          />
        </div>
      </div>
      <img loading="lazy" src={cover} alt="cover" className={css.cover} />
    </div>
  );
});

export default FooterHeader.displayName = 'FooterHeader';
