import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import { getLanguageFromPath, parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import { Button, LimitedAccessBanner, Modal, ModalMissingInformation } from '..';
import SearchIcon from './SearchIcon';
import css from './Topbar.module.css';
import { useConfiguration } from '../../context/configurationContext';
import routeConfiguration from '../../routing/routeConfiguration';
import { withViewport } from '../../util/uiHelpersOld';
import TopbarSearchForm from './TopbarSearchForm/TopbarSearchForm';
import TopbarMobileMenu from './TopbarMobileMenu/TopbarMobileMenu';
import TopbarDesktop from './TopbarDesktop/TopbarDesktop';
import { string } from 'prop-types';
import { bool } from 'prop-types';
import { array } from 'prop-types';
import { number } from 'prop-types';
import { func } from 'prop-types';
import { shape } from 'prop-types';
import { intlShape } from '../../util/reactIntl';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const redirectToURLWithModalState = (history, location, modalStateParam) => {
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (history, location, modalStateParam) => {
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = ({ show }) => {
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: PropTypes.bool.isRequired,
};

const TopbarComponent = props => {
  const {
    className,
    rootClassName,
    desktopClassName,
    isAuthenticated,
    authScopes,
    authInProgress,
    currentUser,
    currentUserHasListings,
    currentUserHasOrders,
    currentPage,
    notificationCount,
    viewport,
    location,
    onManageDisableScrolling,
    onResendVerificationEmail,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    showGenericError,
    onLogout,
    history,
    currentSearchParams,
  } = props;

  const intl = useIntl();
  const config = useConfiguration();

  /*   const handleMobileMenuOpen = useCallback(() => {
    redirectToURLWithModalState(history, location, 'mobilemenu');
  }, [history, location]); */

  const handleMobileMenuClose = useCallback(() => {
    redirectToURLWithoutModalState(history, location, 'mobilemenu');
  }, [history, location]);

  const handleMobileSearchOpen = useCallback(() => {
    redirectToURLWithModalState(history, location, 'mobilesearch');
  }, [history, location]);

  const handleMobileSearchClose = useCallback(() => {
    redirectToURLWithoutModalState(history, location, 'mobilesearch');
  }, [history, location]);

  const handleSubmit = useCallback(
    values => {
      const keywords = values.keywords;
      const { search, selectedPlace } = values.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = config.sortSearchByDistance ? { origin } : {};
      const searchParams = {
        keywords,
        ...currentSearchParams,
        ...originMaybe,
        address: search,
        bounds,
      };
      history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {
            lang: getLanguageFromPath(location.pathname),
          },
          searchParams
        )
      );
    },
    [history, currentSearchParams, config]
  );

  const handleLogout = useCallback(() => {
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration(), {
        lang: getLanguageFromPath(location.pathname),
      });

      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        if (window !== undefined) window.location = path;
      }
      if (typeof window !== 'undefined') {
        window.location.reload();
      }
    });
  }, [onLogout, history, config]);

  const { mobilemenu, mobilesearch, address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
  const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

  const mobileMenu = (
    <TopbarMobileMenu
      isAuthenticated={isAuthenticated}
      currentUserHasListings={currentUserHasListings}
      currentUser={currentUser}
      onLogout={handleLogout}
      notificationCount={notificationCount}
      currentPage={currentPage}
    />
  );

  const locationFieldsPresent = config.sortSearchByDistance
    ? address && origin && bounds
    : address && bounds;
  const initialSearchFormValues = {
    location: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
      : null,
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <LimitedAccessBanner
        isAuthenticated={isAuthenticated}
        authScopes={authScopes}
        currentUser={currentUser}
        onLogout={handleLogout}
        currentPage={currentPage}
      />
      <Button
        rootClassName={location.pathname === '/s' ? css.searchMenu : css.searchMenu_hidden}
        onClick={handleMobileSearchOpen}
        title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
        data-elbaction={'click:click topbar-accmobileecta'}
      >
        <SearchIcon className={css.searchMenuIcon} />
      </Button>
      <TopbarDesktop
        className={desktopClassName}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        currentPage={currentPage}
        initialSearchFormValues={initialSearchFormValues}
        intl={intl}
        isAuthenticated={isAuthenticated}
        notificationCount={notificationCount}
        onLogout={handleLogout}
        onSearchSubmit={handleSubmit}
      />

      <Modal
        id="TopbarMobileMenu"
        isOpen={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {authInProgress ? null : mobileMenu}
      </Modal>
      <Modal
        id="TopbarMobileSearch"
        containerClassName={css.modalContainer}
        isOpen={isMobileSearchOpen}
        onClose={handleMobileSearchClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.searchContainerBackground}>
          <div className={css.searchContainer}>
            <TopbarSearchForm
              onSubmit={handleSubmit}
              initialValues={initialSearchFormValues}
              isMobile
              handleLocationChange={() => {}}
            />
            <p className={css.mobileHelp}>
              <FormattedMessage id="Topbar.searchHelp" />
            </p>
          </div>
        </div>
      </Modal>
      <ModalMissingInformation
        currentUser={currentUser}
        currentUserHasListings={currentUserHasListings}
        currentUserHasOrders={currentUserHasOrders}
        onClose={() => {}}
        sendVerificationEmailInProgress={sendVerificationEmailInProgress}
        sendVerificationEmailError={sendVerificationEmailError}
        onResendVerificationEmail={onResendVerificationEmail}
        onManageDisableScrolling={onManageDisableScrolling}
      />
      <GenericError show={showGenericError} />
    </div>
  );
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(withViewport, injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
