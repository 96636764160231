import { FormattedMessage } from 'react-intl';
import { ecuadorListings } from './ecuador';
import { indonesiaListings } from './indonesien';
import { italyListings } from './italien';
import { colombiaListings } from './kolumbien';
import { namibiaListings } from './namibia';
import { spainListings } from './spanien';
import colombia from '../../assets/../../../../assets/impactAccomodationsPics/icons/colombia.webp';
import ecuador from '../../assets/../../../../assets/impactAccomodationsPics/icons/ecuador.webp';
import indonesia from '../../assets/../../../../assets/impactAccomodationsPics/icons/indonesia.webp';
import italy from '../../assets/../../../../assets/impactAccomodationsPics/icons/italy.webp';
import spain from '../../assets/../../../../assets/impactAccomodationsPics/icons/spain.webp';
import namibia from '../../assets/../../../../assets/impactAccomodationsPics/icons/namibia.webp';

export const additionnalButtons = [
  {
    id: 2,
    categoryName: <FormattedMessage id="landing.accomodations.italy" />,
    categoryIcon: italy,
    category: italyListings,
    name: 'italy',
  },
  {
    id: 5,
    categoryName: <FormattedMessage id="landing.accomodations.spain" />,
    categoryIcon: spain,
    category: spainListings,
    name: 'spain',
  },
  {
    id: 3,
    categoryName: <FormattedMessage id="landing.accomodations.colombia" />,
    categoryIcon: colombia,
    category: colombiaListings,
    name: 'colombia',
  },
  {
    id: 1,
    categoryName: <FormattedMessage id="landing.accomodations.indonesia" />,
    categoryIcon: indonesia,
    category: indonesiaListings,
    name: 'indonesia',
  },
  {
    id: 0,
    categoryName: <FormattedMessage id="landing.accomodations.ecuador" />,
    categoryIcon: ecuador,
    category: ecuadorListings,
    name: 'ecuador',
  },
  {
    id: 4,
    categoryName: <FormattedMessage id="landing.accomodations.namibia" />,
    categoryIcon: namibia,
    category: namibiaListings,
    name: 'namibia',
  },
];
