import ecuador1 from '../../assets/../../../../assets/impactAccomodationsPics/ecuador/ecuador1.webp';
import ecuador2 from '../../assets/../../../../assets/impactAccomodationsPics/ecuador/ecuador2.webp';
import ecuador3 from '../../assets/../../../../assets/impactAccomodationsPics/ecuador/ecuador3.webp';
import ecuador4 from '../../assets/../../../../assets/impactAccomodationsPics/ecuador/ecuador4.webp';
import ecuador5 from '../../assets/../../../../assets/impactAccomodationsPics/ecuador/ecuador5.webp';
import ecuador6 from '../../assets/../../../../assets/impactAccomodationsPics/ecuador/ecuador6.webp';
import ecuador7 from '../../assets/../../../../assets/impactAccomodationsPics/ecuador/ecuador7.webp';
import ecuador8 from '../../assets/../../../../assets/impactAccomodationsPics/ecuador/ecuador8.webp';

export const ecuadorListings = [
  {
    link: '/l/traditional-mountain-ranch-doublebedroom/672d21e6-2806-4249-81ff-24dd56e52f6b',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'traditional-mountain-ranch-doublebedroom',
      id: '672d21e6-2806-4249-81ff-24dd56e52f6b',
    },
    name: 'Traditional Mountain Ranch',
    name_de: 'Traditionelle Berg Ranch',
    price: ' 116€',
    location: 'Machachi, Ecuador',
    projectType: ['nature', 'animal'],
    image: ecuador1,
    finalReviews: '4.7',
    totalFinalReviews: 326,
  },
  {
    link:
      '/l/ecoturismo-comunitario-yunguilla-entire-accomodation/63a8df6a-2550-4c28-8839-d0b354e53301',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ecoturismo-comunitario-yunguilla-entire-accomodation',
      id: '63a8df6a-2550-4c28-8839-d0b354e53301',
    },
    name: 'Private Room Cloud Forest',
    name_de: 'Privatzimmer Nebelwald',
    price: ' 48€',
    location: 'Quito, Ecuador',
    projectType: ['nature'],
    image: ecuador2,
    finalReviews: '5',
    totalFinalReviews: 1,
  },
  {
    link:
      '/l/napo-cultural-center-responsible-travel-sa-doublebedroom/656a50f4-c3d6-4cbb-9188-4fb3a9f1e196',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'napo-cultural-center-responsible-travel-sa-doublebedroom',
      id: '656a50f4-c3d6-4cbb-9188-4fb3a9f1e196',
    },
    name: 'Amazon Lodge',
    name_de: 'Amazonas Lodge',
    price: ' 369€',
    location: 'Parque Nacional Yasuní, Ecuador',
    projectType: ['nature', 'animal'],
    image: ecuador3,
    finalReviews: '4.6',
    totalFinalReviews: 4.6,
  },
  {
    link: '/l/villa-doris-nono-doublebedroom/6560b5d1-44aa-477e-9556-80951e2e09fe',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'villa-doris-nono-doublebedroom',
      id: '6560b5d1-44aa-477e-9556-80951e2e09fe',
    },
    name: 'Double Room Country House',
    name_de: 'Doppelzimmer Landhaus',
    price: ' 108€',
    location: 'Quito, Ecuador',
    projectType: ['nature', 'equality'],
    image: ecuador4,
    finalReviews: '5',
    totalFinalReviews: 5,
  },
  {
    link: '/l/turismo-comunitario-salasaka-twobedroom/6065caa6-39a5-438b-a862-7fd72e8943ee',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'turismo-comunitario-salasaka-twobedroom',
      id: '6065caa6-39a5-438b-a862-7fd72e8943ee',
    },
    name: 'Community Homestay',
    name_de: 'Community Homestay',
    price: ' 50€',
    location: 'Salasaca, Ecuador',
    projectType: ['nature', 'education'],
    image: ecuador5,
    finalReviews: '5',
    totalFinalReviews: 5,
  },
  {
    link:
      '/l/un-poco-del-choc-biological-station-reserve-doublebedroom/60ef5b8d-6522-4a26-8f90-59ca26caa873',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'un-poco-del-choc-biological-station-reserve-doublebedroom',
      id: '60ef5b8d-6522-4a26-8f90-59ca26caa873',
    },
    name: 'Chocó Station & Reserve',
    name_de: 'Chocó-Station & Reserve',
    price: ' 118€',
    location: 'Gualea-Quito, Ecuador',
    projectType: ['nature', 'education'],
    image: ecuador6,
    finalReviews: '5',
    totalFinalReviews: 5,
  },
  {
    link: '/l/r-o-muchacho-shared-bedroom/62827b0e-6c9b-4d56-a355-7b79a06b0d6f',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'r-o-muchacho-shared-bedroom',
      id: '62827b0e-6c9b-4d56-a355-7b79a06b0d6f',
    },
    name: 'Dorm in Ecolodge',
    name_de: 'Schlafsaal in Ecolodge',
    price: ' 12€',
    location: 'Canoa, Ecuador',
    projectType: ['nature', 'education'],
    image: ecuador7,
    finalReviews: '4.5',
    totalFinalReviews: 4.5,
  },
  {
    link: '/l/runa-tupari-responsible-travel-sa-doublebedroom/64e666f9-9013-4738-838c-9df05bf7900e',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'runa-tupari-responsible-travel-sa-doublebedroom',
      id: '64e666f9-9013-4738-838c-9df05bf7900e',
    },
    name: 'Family Homestay',
    name_de: 'Familien Homestay',
    price: ' 113€',
    location: 'Cotacachi , Ecuador',
    projectType: ['nature', 'animal'],
    image: ecuador8,
    finalReviews: '0',
    totalFinalReviews: 0,
  },
];
