import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './NoSearchResultsMaybe.module.css';

const NoSearchResultsMaybe = props => {
  const { listingsAreLoaded, totalItems, location, resetAll } = props;
  const hasNoResult = listingsAreLoaded && totalItems === 0;
  const hasSearchParams = location.search?.length > 0;
  const searchParams = new URLSearchParams(location?.search);
  const failedSearch = searchParams.get('address') ?  searchParams.get('address') :  searchParams.get('bounds');
  return totalItems === 0 ? (
    hasNoResult ? (
      <div className={css.noSearchResults}
      data-elb="searchp" data-elb-searchp={`name:noresultsfor-${failedSearch};`} data-elbaction="visible">
        <FormattedMessage id="SearchPage.noResults" />
        <br />
        {hasSearchParams ? (
          <button className={css.resetAllFiltersButton} onClick={e => resetAll(e)}>
            <FormattedMessage id={'SearchPage.resetAllFilters'} />
          </button>
        ) : null}
      </div>
    ) : (
      <div className={css.noSearchResults}>
        <FormattedMessage id="MainPanelHeader.NoResult" />
      </div>
    )
  ) : null;
};

export default NoSearchResultsMaybe;
