import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './MainPanelHeader.module.css';
import { H1 } from '../../../components';

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    isSortByActive,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    noResultsInfo,
    setListingTypeFilter,
    searchFilterIsPlain,
    listingTypeFilter,
    header,
  } = props;

  return (
    <div className={css.searchHeaderWrapper}>
      <div className={className}>
        <div className={css.mainFilterWrapper}>
          <div className={css.children}>{children}</div>
          {searchFilterIsPlain ? (
            <></>
          ) : (
            <div className={css.listingTypeFiltersWrapper}>
              <button
                className={classNames(css.listingFilterButton, {
                  [css.selected]: listingTypeFilter === null,
                })}
                onClick={() => setListingTypeFilter(null)}
                data-elbaction="click: click allFilter"
              >
                <FormattedMessage id="MainPanelHeader.listingTypesFilter.All" />
              </button>
              <button
                className={classNames(css.listingFilterButton, {
                  [css.selected]: listingTypeFilter === 'impact-experience',
                })}
                onClick={() => setListingTypeFilter('impact-experience')}
                data-elbaction="click: click expFilter"
              >
                <FormattedMessage id="MainPanelHeader.listingTypesFilter.Experiences" />
              </button>
              <button
                className={classNames(css.listingFilterButton, {
                  [css.selected]: listingTypeFilter === 'impact-accommodation',
                })}
                onClick={() => setListingTypeFilter('impact-accommodation')}
                data-elbaction="click: click accmFilter"
              >
                <FormattedMessage id="MainPanelHeader.listingTypesFilter.Stays" />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={css.searchOptions}>
        <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            <H1 className={css.title}>{header}</H1>
          </span>
          <span className={css.resultsFoundText}>
            {searchInProgress ? (
              <FormattedMessage id="MainPanelHeader.loadingResults" />
            ) : (
              <FormattedMessage
                id="MainPanelHeader.foundResults"
                values={{ count: resultsCount }}
              />
            )}
          </span>
        </div>
      </div>
      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
