import React, { useEffect, useRef, useState } from 'react';
import css from './SectionImpactAccomodations.module.css';
import {
  categoryDefault,
  category0,
  category2,
  category4,
  category5,
  category6,
  category8,
  category9,
  category10,
  category11,
  category13,
  category15,
  category16,
} from './impactAccomodationsList.enum';
import { FormattedMessage, useIntl, createIntl } from 'react-intl';
import {
  natureIcon,
  adventureIcon,
  beachIcon,
  breakfastIcon,
  campIcon,
  cookingIcon,
  handCraftIcon,
  farmIcon,
  tinyHouseIcon,
  backPackIcon,
  familyIcon,
  amazingviewsIcon,
  countrySideIcon,
  islandsIcon,
  lakeIcon,
  affordableIcon,
  remoteIcon,
} from './categoryIcons';
import star from '../../../../assets/Experiencehighlights/activity.png';
import englishTranslations from '../../../../translations/en.json';
import { additionnalButtons } from './addittionnalListings/additionnalListings';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CategoryButtons = ({ selectedCategory, setSelectedCategory, isMobileDevice }) => {
  const history = useHistory();
  const location = useLocation();

  const intl = useIntl();
  const language = useSelector(state => state.ui.language);
  const [englishMessages, setEnglishMessages] = useState(null);

  useEffect(() => {
    if (language === 'en' && intl.messages) {
      setEnglishMessages({ ...intl.messages });
    }
  }, [language]);

  const getEnglishValue = messageId => {
    try {
      if (!englishMessages) {
        console.log('No English messages cached yet');
        return messageId;
      }

      // Create a new intl instance with our cached English messages
      const tempIntl = createIntl({
        locale: 'en',
        defaultLocale: 'en',
        messages: englishMessages,
      });

      return tempIntl.formatMessage({ id: messageId });
    } catch (error) {
      console.error('Translation error:', error);
      return messageId;
    }
  };

  let box;
  if (typeof window !== 'undefined' && window?.document) {
    box = document.getElementById('desktopSlider');
  }

  const categoryData = [
    {
      id: 6,
      categoryName: <FormattedMessage id="landing.accomodations.categoryDefault" />,
      categoryIcon: star,
      category: categoryDefault,
      name: 'stays',
    },
    ...additionnalButtons,
    /*  {
      id: 7,
      categoryName: <FormattedMessage id="landing.accomodations.category6" />,
      categoryIcon: beachIcon,
      category: category6,
    },*/
    {
      id: 8,
      categoryName: <FormattedMessage id="landing.accomodations.category8" />,
      categoryIcon: farmIcon,
      category: category8,
      name: 'farm',
    },
    {
      id: 9,
      categoryName: <FormattedMessage id="landing.accomodations.category4" />,
      categoryIcon: natureIcon,
      category: category4,
      name: 'nature',
    },
    {
      id: 10,
      categoryName: <FormattedMessage id="landing.accomodations.category2" />,
      categoryIcon: familyIcon,
      category: category2,
      name: 'family',
    },
    {
      id: 17,
      categoryName: <FormattedMessage id="landing.accomodations.category15" />,
      categoryIcon: islandsIcon,
      category: category15,
      name: 'islands',
    },
  ];

  const handleCategoryClick = category => {
    setSelectedCategory(category.category);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('type', category.name);

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const typeFromUrl = searchParams.get('type');

    if (typeFromUrl) {
      const selectedType = categoryData.find(item => item.name == typeFromUrl);
      setSelectedCategory(selectedType.category);
    }
  }, [location.search]);

  const sliderRef = useRef(null);
  const boxRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;

    const handleTouchStart = event => {
      const startX = event.touches[0].clientX;
      event.target.dataset.startX = startX; // Store startX on the element itself
    };

    const handleTouchMove = event => {
      const startX = parseFloat(event.target.dataset.startX);
      const touchX = event.touches[0].clientX;
      const deltaX = startX - touchX;

      // Amplifying the deltaX by multiplying it with a factor (e.g., 1.5 or 2)
      const scrollFactor = 30; // Adjust this factor based on desired responsiveness
      const effectiveDeltaX = deltaX * scrollFactor;

      if (slider) {
        slider.scrollLeft += effectiveDeltaX;
        event.target.dataset.startX = touchX; // Update startX for the next move
      }
      event.preventDefault(); // Prevent the window from scrolling
    };

    if (slider) {
      slider.addEventListener('touchstart', handleTouchStart, false);
      slider.addEventListener('touchmove', handleTouchMove, false);
    }

    return () => {
      if (slider) {
        slider.removeEventListener('touchstart', handleTouchStart);
        slider.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [isMobileDevice]);

  /*  const handleNextClick = () => {
    if (boxRef.current) {
      const width = boxRef.current.clientWidth;
      boxRef.current.scrollLeft += width;
    }
  };

  const handlePreviousClick = () => {
    if (boxRef.current) {
      const width = boxRef.current.clientWidth;
      boxRef.current.scrollLeft -= width;
    }
  }; */

  return (
    <>
      {isMobileDevice ? (
        <div className={css.mobileCategories}>
          <div className={css.mobileButtonsWrapper}>
            <div className={css.mobileSlider} ref={sliderRef}>
              {categoryData.map((category, index) => (
                <>
                  <button
                    id={category.id}
                    key={category.id}
                    className={
                      selectedCategory === category.category
                        ? css.mobileCategoryButtonActive
                        : css.mobileCategoryButton
                    }
                    onClick={() => {
                      handleCategoryClick(category);
                    }}
                    data-elb-categories={category.id}
                    data-elbaction={
                      'click:click lp-impctcat-' + getEnglishValue(category.categoryName.props.id)
                    }
                  >
                    <img
                      src={category.categoryIcon}
                      style={{ paddingRight: '2px', width: '28px', height: '28px' }}
                      alt="category icon"
                    />
                    <div style={{ paddingBottom: '5px' }}>
                      <span className={css.categoryTxtMobile}>{category.categoryName}</span>
                    </div>
                  </button>
                </>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className={css.desktopBtnOuterWrapper}>
          {/* <button className={css.sliderBtn} onClick={handlePreviousClick}>
            <p className={css.sliderBtnTxt}>&lt;</p>
          </button> */}
          <div id="desktopSlider" className={css.desktopButtonsWrapper} ref={boxRef}>
            {categoryData.map((category, index) => (
              <>
                <button
                  key={category.id}
                  className={
                    selectedCategory === category.category
                      ? css.desktopCategoryButtonActive
                      : css.desktopCategoryButton
                  }
                  onClick={() => {
                    handleCategoryClick(category);
                  }}
                  data-elb-categories={category.id}
                  data-elbaction={
                    'click:click lp-impctcat-' + getEnglishValue(category.categoryName.props.id)
                  }
                >
                  <img
                    src={category.categoryIcon}
                    style={{ width: '33px', height: '33px' }}
                    alt="socialbnb categories button"
                  />
                  <div style={{ marginBottom: '10px' }}>
                    <span className={css.categoryTxt}>{category.categoryName}</span>
                  </div>
                </button>
                {/* Add a separator after the 1st and 7th elements */}
                {(index === 0 || index === 6) && <div className={css.separator}>{"\u00A0"}</div>}
              </>
            ))}
          </div>
          {/*  <button className={css.sliderBtn} onClick={handleNextClick}>
            <p className={css.sliderBtnTxt}>&gt;</p>
          </button> */}
        </div>
      )}
    </>
  );
};

export default CategoryButtons;
