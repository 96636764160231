import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './skeleton.css';

const NewExperiencesSkeleton = () => {
  return (
    <div
      className="container"
      style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}
    >
      <Skeleton height={30} width="40%" style={{ marginBottom: '20px' }} />

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '20px',
          justifyContent: 'center',
        }}
      >
        <Skeleton height={400} width={300} />

        <div>
          <Skeleton height={200} width={200} />
          <Skeleton height={200} width={200} />
        </div>
        <div>
          <Skeleton height={200} width={400} />
          <div style={{ display: 'flex', gap: '20px' }}>
            <Skeleton height={200} width={190} />
            <Skeleton height={200} width={190} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewExperiencesSkeleton;
