import { memo, useEffect, useRef, useState } from 'react';
import { categoryConfig } from './categoryConfig';
import { FormattedMessage } from 'react-intl';
import PublicationSection from './PublicationSection';

const HeroImage = ({ currentCategory, children, header, css }) => {
  const categoryData = categoryConfig[currentCategory] || categoryConfig.stays;
  const { heroImage, heroText } = categoryData;

  const textWrapperRef = useRef(null); // Ref for measuring content height
  const [contentHeight, setContentHeight] = useState('auto');

  useEffect(() => {
    const updateContentHeight = () => {
      if (textWrapperRef.current) {
        setContentHeight(textWrapperRef.current.offsetHeight);
      }
    };

    updateContentHeight();
    window?.addEventListener('resize', updateContentHeight);

    return () => window?.removeEventListener('resize', updateContentHeight);
  }, []);

  
  useEffect(() => {
    let isMounted = true;

    const preloadImages = () => {
      if (!isMounted) return;

      const imagesToPreload = Object.values(categoryConfig)
        .map(({ heroImage }) => heroImage)
        .filter(img => img !== categoryData.heroImage);

      imagesToPreload.forEach(imageUrl => {
        const link = document.createElement('link');
        link.rel = 'preload';
        link.as = 'image';
        link.href = imageUrl;
        link.crossOrigin = 'anonymous';
        document.head.appendChild(link);
      });
    };

    if ('requestIdleCallback' in window) {
      requestIdleCallback(preloadImages, { timeout: 2000 });
    } else {
      setTimeout(preloadImages, 1000);
    }

    return () => {
      isMounted = false;
      const links = document.head.querySelectorAll('link[rel="preload"][as="image"]');
      links.forEach(link => link.remove());
    };
  }, [categoryData.heroImage]);

  return (
    <div className={css.heroWrapper} id="heroWrapper">
      <div
        className={css.heroBackground}
        style={{ height: contentHeight }} // Dynamically set height
      >
        <img
          className={css.heroBackgroundStatic}
          src={heroImage}
          alt="Background Hero Image"
          loading="eager"
          decoding="async"
          fetchpriority="high"
        />
      </div>
      <div className={css.textWrapper} ref={textWrapperRef}>
        <div className={css.textSectionWrapper}>
          <div className={css.headerWrapperRow}>
            <h1 className={css.header}>
              <FormattedMessage
                id={header}
                values={{
                  span: content => <span>{content}</span>,
                }}
              />
            </h1>
          </div>
          <div className={css.headerWrapperRow}>
            <p className={css.subHeader}>{heroText.subHeadline}</p>
          </div>
          {children}
          <div className={css.pressSection}>
            <PublicationSection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(HeroImage);
