import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './skeleton.css';

const WhySocialbnbSectionSkeleton = () => {
  return (
    <div
      className="container"
      style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}
    >
      <Skeleton height={30} width="40%" />

      <div style={{ marginBottom: '20px' }}>
        <Skeleton count={4} height={10} width="80%" />
      </div>

      <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
        <div style={{ width: 300 }}>
          <div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <Skeleton height={30} width={140} style={{ marginBottom: '10px' }} />
              <Skeleton height={60} width={60} />
            </div>
            <div>
              <Skeleton count={4} height={10} width="80%" />
              <Skeleton height={10} width="75%" />
              <Skeleton height={10} width="60%" />
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <Skeleton height={30} width={140} style={{ marginBottom: '10px' }} />
              <Skeleton height={60} width={60} />
            </div>
            <div>
              <Skeleton count={4} height={10} width="80%" />
              <Skeleton height={10} width="75%" />
              <Skeleton height={10} width="60%" />
            </div>
          </div>
        </div>
        <div style={{ width: 300, marginTop: 20 }}>
          <div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <Skeleton height={30} width={140} style={{ marginBottom: '10px' }} />
              <Skeleton height={60} width={60} />
            </div>
            <div>
              <Skeleton count={4} height={10} width="80%" />
              <Skeleton height={10} width="75%" />
              <Skeleton height={10} width="60%" />
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <Skeleton height={30} width={140} style={{ marginBottom: '10px' }} />
              <Skeleton height={60} width={60} />
            </div>
            <div>
              <Skeleton count={4} height={10} width="80%" />
              <Skeleton height={10} width="75%" />
              <Skeleton height={10} width="60%" />
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
        <div style={{ width: '50%' }}>
          <Skeleton height={30} width={150} style={{ marginTop: '20px', marginBottom: '10px' }} />
          <Skeleton count={4} height={10} width="80%" />
        </div>

        <div style={{ marginTop: '20px', width: 300 }}>
          <Skeleton height={40} width={300} style={{ marginBottom: '10px' }} />
          <Skeleton height={40} width={300} style={{ marginBottom: '10px' }} />
          <Skeleton height={40} width={300} />
        </div>
      </div>
    </div>
  );
};

export default WhySocialbnbSectionSkeleton;
