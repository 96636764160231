export const transformReviews = initalReviews => {
  const reviews = initalReviews.map(review => ({
    author: review.author.id.uuid,
    content: review.attributes.content,
    createdAt: review.attributes.createdAt,
    customerId: review.author.id.uuid,
    listingId: '',
    rating: review.attributes.rating,
    reviewId: review.id.uuid,
  }));
  const finalRating = reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length;
  return { reviews, reviewsAmount: reviews.length, finalRating };
};
