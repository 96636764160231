import React, { useEffect, useState } from 'react';
import IconReviewStarMini from '../ReviewRatings/IconReviewStarMini/IconReviewStarMini';
import { transformReviews } from '../Utils/formatReviews.util';

const ReviewRatings = ({
  currentListing,
  showDot,
  showText,
  textColor,
  onShowModalFromOutside,
  isHostModal,
  displayBothReviews,
  starContainerClass,
  textSize,
  isSmall = true,
  fallBackReviews = [],
}) => {
  const [reviewsAmount, setReviewsAmount] = useState(0);
  const [finalRating, setFinalRating] = useState(0);
  const [reviews, setReviews] = useState(null);

  useEffect(() => {
    if (currentListing?.attributes?.publicData) {
      const finalReviews = currentListing.attributes.publicData?.finalReviews ?? 0;
      const location = currentListing.attributes.publicData?.location ?? null;
      const internalReviews = currentListing.attributes.publicData?.internalReviews ?? null;
      const rawReviews = internalReviews?.reviews || [];
      const googleReviews =
        location && location?.reviews?.length
          ? location.reviews
          : currentListing?.attributes?.publicData?.googleReviews
          ? currentListing?.attributes?.publicData?.googleReviews
          : [];
      setReviews([...rawReviews, ...googleReviews]);
      setFinalRating(finalReviews);
      setReviewsAmount(rawReviews.length + googleReviews.length);
    }
  }, [currentListing]);

  if (!reviews || reviewsAmount === 0 || !finalRating) {
    if (fallBackReviews.length > 0) {
      const formattedReviews = transformReviews(fallBackReviews);
      return (
        <div
          data-elb-accomodation={`rating:${finalRating};reviews:${reviewsAmount}`}
          data-elbaction="wait(1000):reviewsLoaded"
        >
          <IconReviewStarMini
            value={formattedReviews.finalRating}
            reviews={formattedReviews.reviews}
            onShowModalFromOutside={onShowModalFromOutside}
            starSize={!isHostModal}
            showDot={showDot}
            textColor={'black'}
            reviewsNumber={formattedReviews.reviewsAmount}
            isSmall={isSmall}
            classNameContainer={''}
            showText={showText}
            textSize={textSize}
          />
        </div>
      );
    } else {
      return <></>;
    }
  }

  return (
    <div
      data-elb-accomodation={`rating:${finalRating};reviews:${reviewsAmount}`}
      data-elbaction="wait(1000):reviewsLoaded"
    >
      <IconReviewStarMini
        value={finalRating}
        reviews={reviews}
        onShowModalFromOutside={onShowModalFromOutside}
        starSize={!isHostModal}
        showDot={showDot}
        textColor={'black'}
        reviewsNumber={reviewsAmount}
        isSmall={isSmall}
        classNameContainer={''}
        showText={showText}
        textSize={textSize}
      />
    </div>
  );
};

export default ReviewRatings;
