import React, { useEffect, useState } from 'react';
import image1 from './assets/natgeo.png';
import image2 from './assets/geo.png';
import image3 from './assets/zdf.png';
import image4 from './assets/galileo.png';
import image5 from './assets/faz.png';
import image6 from './assets/guardian.png';
import stars from './assets/stars.png';
import css from './HeroSection.module.css';
import { FormattedMessage } from 'react-intl';

const externalLinks = {
  natgeo:
    'https://www.nationalgeographic.de/reise-und-abenteuer/2024/10/wie-man-mit-einer-reise-in-den-amazonas-den-regenwald-schuetzen-kann',
  zdf: 'https://www.zdf.de/gesellschaft/plan-b/plan-b-da-geht-was-deutschland-108.html',
  galileo:
    'https://www.galileo.tv/abenteuer/socialbnb-charity-urlaub-soziale-projekte-unterstuetzen/',
  faz:
    'https://www.faz.net/aktuell/wirtschaft/unternehmen/wer-bei-socialbnb-bucht-finanziert-soziale-projekte-18154255.html',
  guardian:
    'https://www.theguardian.com/travel/2025/jan/15/a-break-from-the-accommodation-mega-sites-the-best-alternative-booking-platforms?CMP=Share_iOSApp_Other',
};

const PublicationSection = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the window object is available (client-side only)
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 1024);
      };

      // Set the initial state
      handleResize();

      // Add event listener for resizing
      window.addEventListener('resize', handleResize);

      // Cleanup event listener on unmount
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return (
    <div className={css.pressSection}>
      {isMobile ? null : <img src={image2} alt="GEO" />}
      <a href={externalLinks.natgeo} target="_blank" rel="noopener noreferrer">
        <img src={image1} alt="National Geographic" />
      </a>
      <a href={externalLinks.zdf} target="_blank" rel="noopener noreferrer">
        <img src={image3} alt="ZDF" />
      </a>
      <div className={css.pressText}>
        {isMobile ? (
          <span>
            <FormattedMessage id="LandingPage.heroSectionPublicationTitleShort" />
          </span>
        ) : (
          <span>
            <FormattedMessage id="LandingPage.heroSectionPublicationTitleLong" />
          </span>
        )}
        <img className={css.stars} src={stars} alt="Stars" />
      </div>
      {isMobile ? null : (
        <a href={externalLinks.galileo} target="_blank" rel="noopener noreferrer">
          <img src={image4} alt="Galileo" />
        </a>
      )}

      <a href={externalLinks.faz} target="_blank" rel="noopener noreferrer">
        <img src={image5} alt="FAZ" />
      </a>
      <a href={externalLinks.guardian} target="_blank" rel="noopener noreferrer">
        <img src={image6} alt="The Guardian" />
      </a>
    </div>
  );
};

export default PublicationSection;
