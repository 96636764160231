import React, { memo } from 'react';
import css from './Main.module.css';
import { FormattedMessage } from 'react-intl';
import un from './assets/main/un.webp';
import giz from './assets/main/giz.webp';
import forum from './assets/main/forum.webp';
import bundesmin from './assets/main/bundesmin.webp';
import ltc from './assets/main/ltc.webp';
import ecpat from './assets/main/ecpat.webp';

const logoLinks = {
  un: 'https://www.unwto.org/unwto-awake-tourism-challenge',
  giz: 'https://www.giz.de/de/html/index.html',
  forum: 'https://forumandersreisen.de/',
  bundesmin: 'https://www.bmwk.de/Navigation/DE/Home/home.html',
  ltc: 'https://www.ltandc.org/',
  ecpat: 'https://ecpat.de/',
};

const LogoWithLink = memo(({ src, alt, href, elbwalker }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    style={{ display: 'block' }}
    data-elbaction={`click:click footer-supportedBy-${elbwalker}`}
  >
    <img loading="lazy" src={src} alt={alt} className={css.logo} />
  </a>
));

LogoWithLink.displayName = 'LogoWithLink';

export const FooterMain = memo(() => {
  return (
    <>
      <h2 className={css.sectionh2Title}>
        <FormattedMessage id="footer.main.title" />
      </h2>
      <div
        className={css.container}
        data-elb="section"
        data-elb-section="name:footerButtom;position:13"
        data-elbaction="visible:section vis lp-feat-section"
      >
        <div className={css.logosDesktop}>
          <LogoWithLink src={un} alt="un" href={logoLinks.un} elbwalker={'unTourism'} />
          <LogoWithLink src={giz} alt="giz" href={logoLinks.giz} elbwalker={'giz'} />
          <LogoWithLink src={forum} alt="forum" href={logoLinks.forum} elbwalker={'forum'} />
          <LogoWithLink
            src={bundesmin}
            alt="bundesmin"
            href={logoLinks.bundesmin}
            elbwalker={'bundesmin'}
          />
          <LogoWithLink src={ltc} alt="ltc" href={logoLinks.ltc} elbwalker={'ltc'} />
          <LogoWithLink src={ecpat} alt="ecpat" href={logoLinks.ecpat} elbwalker={'ecpat'} />
        </div>
        <div className={css.logosMobile}>
          <div className={css.logosMobileRow}>
            <LogoWithLink src={un} alt="un" href={logoLinks.un} elbwalker={'unTourism'} />
          </div>
          <div className={css.logosMobileRow}>
            <LogoWithLink src={ltc} alt="ltc" href={logoLinks.ltc} elbwalker={'ltc'} />
            <LogoWithLink src={ecpat} alt="ecpat" href={logoLinks.ecpat} elbwalker={'ecpat'} />
          </div>
          <div className={css.logosMobileRow}>
            <LogoWithLink src={forum} alt="forum" href={logoLinks.forum} elbwalker={'forum'} />
          </div>
          <div className={css.logosMobileRow}>
            <LogoWithLink src={giz} alt="giz" href={logoLinks.giz} elbwalker={'giz'} />
            <LogoWithLink
              src={bundesmin}
              alt="bundesmin"
              href={logoLinks.bundesmin}
              elbwalker={'bundesmin'}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default FooterMain.displayName = 'FooterMain';
