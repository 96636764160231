import React, { useEffect, useRef, useState } from 'react';
import css from './AmbassadorPage.module.css';
import HubspotForm from './HubspotForm';
import closeicon from './../../assets/close.webp';
import { FormattedMessage } from 'react-intl';
import { Button } from './../../components';
import heroImg from '../../assets/ambassadorPage/topPageAmbassador.webp';

const AmbassadorHeroSection = ({ pageLanguage }) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleDrawer() {
    setIsOpen(!isOpen);
  }

  const formRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const element = document.getElementById('heroTitle');
      element.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    document.body.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, []);
  return (
    <div className={css.wrapperPosition} id="heroTitle">
      <div className={css.heroSection}>
        <div className={css.heroText}>
          <h1 className={css.heroTitle}>
            <FormattedMessage id="ImpactAmb.h1.part1" />
            <span className={css.heroSubtitle1}>
              <FormattedMessage id="ImpactAmb.h1.part2" />
            </span>
          </h1>
          <p className={css.heroDescription}>
            <span>
              <FormattedMessage id="ImpactAmb.heroDescr1.1" />
              <br />
              <FormattedMessage id="ImpactAmb.heroDescr1.2" />
              <br />
              <br />
              <strong>
                <FormattedMessage id="ImpactAmb.heroDescrStrong" />
              </strong>
            </span>
          </p>
          <Button className={css.heroButton} onClick={toggleDrawer}>
            <FormattedMessage id="ImpactAmb.heroButton" />
          </Button>
          {isOpen && (
            <div className={css.hubspotDrawer} ref={formRef}>
              <span className={css.closeButton} onClick={toggleDrawer}>
                <img
                  src={closeicon}
                  height="32px"
                  width="40px"
                  style={{
                    marginTop: '-2px',
                    left: '0',
                    top: '0',
                    position: 'absolute',
                  }}
                  alt="impact ambassador social bnb"
                ></img>
              </span>
              <div className={css.formWrapper}>
                <HubspotForm pageLanguage={pageLanguage} />
              </div>
            </div>
          )}
        </div>
        <div className={css.heroImageContainer}>
          <img className={css.heroImage} src={heroImg} alt="ambassadorHeroImage" />
        </div>
      </div>
      <div className={css.heroSubText}>
        <br />
        <h2 className={css.heroSubtitle2}>
          <FormattedMessage id="ImpactAmb.h2.part1" />
        </h2>
        <p className={css.heroDescription}>
          <FormattedMessage id="ImpactAmb.heroDescr2.1" />
        </p>
        <br />
        <h2 className={css.heroSubtitle2}>
          <FormattedMessage id="ImpactAmb.h2.part2" />
        </h2>
        <p className={css.heroDescription}>
          <FormattedMessage id="ImpactAmb.heroDescr2.2" />
        </p>
      </div>
    </div>
  );
};

export default AmbassadorHeroSection;
