import React, { useState } from 'react';
import ResponsiveImage from '../../../../components/ResponsiveImage/ResponsiveImage';
import ImageCarousel from '../../../../components/ImageCarousel/ImageCarousel';
import css from './ImagesDisplay.module.css';
import dots from '../../../../assets/icons/4Dots.png';
import { FormattedMessage } from '../../../../util/reactIntl';
import Modal from '../Utils/Modal/Modal';
import SliderMini from './SliderMini/SliderMini';
import Gallery from 'react-photo-gallery';
/*identify if is mobile */
import { isMobile } from 'react-device-detect';

const ImagesDisplay = props => {
  const { onListing } = props;
  const [showModal, setShowModal] = useState(false);
  const [showSlider, setShowSlider] = useState(false);

  const [gallerySelectedId, setGallerySelectedId] = useState('');
  const [variantsImg, setVariants] = useState(onListing.images);

  const handleViewGallery = () => {
    setShowModal(true);
  };

  const handleCloseGallery = () => {
    setShowModal(false);
  };
  const handleShowSlider = e => {
    setShowSlider(true);
    const targetIndex = Array.from(e.target.parentNode.children).indexOf(e.target);

    setGallerySelectedId(targetIndex);
  };

  const handleCloseSlider = () => {
    setShowSlider(false);
    setShowModal(false);
  };

  const hasImages = onListing.images && onListing.images.length > 0;
  const amount = onListing.images.length;
  const firstImage = hasImages ? onListing.images[0] : null;
  const secondImage = hasImages ? onListing.images[1] : null;
  const thirdImage = hasImages ? onListing.images[2] : null;
  const fourthImage = hasImages ? onListing.images[3] : null;
  const fifthImage = hasImages ? onListing.images[4] : null;

  let mappedArray = [];
  if (onListing.images && onListing.images.length > 0) {
    mappedArray = onListing.images.map((image, index) => {
      // Extracting necessary attributes from each image object
      const { variants } = image.attributes;

      // Creating a new object with required structure using extracted attributes
      if ('scaled-xlarge' in variants) {
        return {
          id: index + 1, // Adding unique ID for each element (optional)
          src: variants['scaled-xlarge']?.url,
          width: Math.random() * (400 - 250) + 250, // here put max and min values for width and height
          height: Math.random() * (260 - 150) + 150,
          name: variants['scaled-xlarge']?.name,
          className: css.containImage,
        };
      } else {
        return {
          id: index + 1, // Adding unique ID for each element (optional)
          src: variants['landscape-crop4x']?.url,
          width: Math.random() * (400 - 250) + 250, // here put max and min values for width and height
          height: Math.random() * (260 - 150) + 150,
          name: variants['landscape-crop4x']?.name,
          className: css.coverImage,
        };
      }
    });
  }

  return (
    <div  data-elbaction={'click:click viewimages'}>
      {isMobile ? (
        <SliderMini
          onMappedArray={mappedArray}
          images={onListing.images}
          onIsOpen={showModal}
          onHandleViewGallery={handleViewGallery}
          onCloseGallery={handleCloseGallery}
        />
      ) : (
        <div className={css.galleryContainer}>
          {variantsImg && (
            <div className={css.gallery}>
              {amount > 0 && (
                <ResponsiveImage
                  onClick={handleViewGallery}
                  rootClassName={css.fullImage}
                  alt=""
                  image={firstImage}
                  variants={[
                    firstImage &&
                    'landscape-crop4x' in firstImage.attributes.variants &&
                    !('scaled-large' in firstImage.attributes.variants)
                      ? 'landscape-crop4x'
                      : 'scaled-large',
                  ]}
                />
              )}

              {amount > 1 && (
                <ResponsiveImage
                  onClick={handleViewGallery}
                  rootClassName={css.mLeftTop}
                  alt=""
                  image={secondImage}
                  variants={[
                    secondImage &&
                    'landscape-crop4x' in secondImage.attributes.variants &&
                    !('scaled-large' in secondImage.attributes.variants)
                      ? 'landscape-crop4x'
                      : 'scaled-large',
                  ]}
                />
              )}
              {amount > 2 && (
                <ResponsiveImage
                  onClick={handleViewGallery}
                  rootClassName={css.mRightTop}
                  alt=""
                  image={thirdImage}
                  variants={[
                    thirdImage &&
                    'landscape-crop4x' in thirdImage.attributes.variants &&
                    !('scaled-large' in thirdImage.attributes.variants)
                      ? 'landscape-crop4x'
                      : 'scaled-large',
                  ]}
                />
              )}

              {amount > 3 && (
                <ResponsiveImage
                  onClick={handleViewGallery}
                  rootClassName={amount === 4 ? css.bottom : css.mLeftBottom}
                  alt=""
                  image={fourthImage}
                  variants={[
                    fourthImage &&
                    'landscape-crop4x' in fourthImage.attributes.variants &&
                    !('scaled-large' in fourthImage.attributes.variants)
                      ? 'landscape-crop4x'
                      : 'scaled-large',
                  ]}
                />
              )}

              {amount > 4 && (
                <ResponsiveImage
                  onClick={handleViewGallery}
                  rootClassName={css.mRightBottom}
                  alt=""
                  image={fifthImage}
                  variants={[
                    fifthImage &&
                    'landscape-crop4x' in fifthImage.attributes.variants &&
                    !('scaled-large' in fifthImage.attributes.variants)
                      ? 'landscape-crop4x'
                      : 'scaled-large',
                  ]}
                />
              )}
            </div>
          )}

          <button className={css.btn} onClick={handleViewGallery}>
            <FormattedMessage id="ListingPage.viewImagesButton" values={{ count: amount }} />{' '}
            <img className={css.btnImg} src={dots} alt="images show a unique experience at a sustainable accomodation - Socialbnb" />{' '}
          </button>

          {showModal && (
            <Modal
              isOpen={showModal}
              onClose={handleCloseGallery}
              classNameModal="galleryModal"
              classNameButton="buttonGallery"
              classNameBtnImage="btnImage"
            >
              {/* using react-photo-gallery  https://www.npmjs.com/package/react-photo-gallery  */}
              <Gallery onClick={handleShowSlider} columns={3} photos={mappedArray} margin={5} />
            </Modal>
          )}

          {showSlider && (
            <Modal
              isOpen={showSlider}
              onClose={handleCloseSlider}
              classNameModal="sliderModal"
              classNameButton="buttonSlider"
              classNameBtnImage="btnImage"
            >
              <ImageCarousel selectedImg={gallerySelectedId} images={onListing.images} />
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};

export default ImagesDisplay;
