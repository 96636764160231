import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './skeleton.css';

const ThisIsSocialbnbSkeleton = () => {
  return (
    <div className="container" style={{ padding: '20px' }}>
      <Skeleton height={20} width="30%" style={{ marginBottom: '20px' }} />

      <Skeleton height={300} width="100%" style={{ marginBottom: '20px' }} />

      <Skeleton height={40} width={150} />
    </div>
  );
};

export default ThisIsSocialbnbSkeleton;
