import React, { useEffect, useState } from 'react';
import { string, func, bool, any, object } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl, useIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpersOld';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import getCountryCodes from '../../translations/countryCodes';

//img

import Bildung from '../../assets/categories/education.webp';
import Naturschutz from '../../assets/categories/environment.webp';
import Tierschutz from '../../assets/categories/animal.webp';
import Equality from '../../assets/categories/equality.webp';
import Health from '../../assets/categories/health.webp';
import Sports from '../../assets/categories/sports.webp';
import NamedLinkNewTab from '../NamedLink/NamedLinkNewTab';
import { extractDaysAndNights } from '../../util/listingPages.util';
import ReviewRatings from '../../containers/ListingPage/ListingPageComponents/ReviewRatings/ReviewRatings';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchReviews } from '../../containers/ListingPage/ListingPage.duck';
import arroRight from '../../assets/icons/arrows/vect1-orange.svg';
import arroLeft from '../../assets/icons/arrows/vect2-orange.svg';
import { useLocation } from 'react-router-dom';
import { Form } from 'react-final-form';
import Spinner from '../../containers/SearchPage/loadingSpinner/loadingSpinner';

const optionImageMap = {
  Bildung: Bildung,
  Naturschutz: Naturschutz,
  Tierschutz: Tierschutz,
  Equality: Equality,
  Health: Health,
  Sports: Sports,
};

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl, isUsd) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price, isUsd);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getReviewsByListingId = (state, listingId) => {
  return state.reviews[listingId] || [];
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 600 });

const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    listing,
    renderSizes,
    setActiveListing,
    fetchAndLogReviews,
  } = props;
  const intl = useIntl();
  const classes = classNames(css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing?.id?.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const [renderComplete, isRenderComplete] = useState(false);
  const [reviewsData, setReviewsData] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(currentListing.images[0]);
  const currentVariant = currentListing?.images[0].attributes?.variants['listing-card']
    ? 'listing-card'
    : 'scaled-medium';
  const location = useLocation();
  const [imagesLoaded, setImagesLoaded] = useState({});

  useEffect(() => {
    isRenderComplete(true);
    const fetchReviews = async () => {
      if (currentListing?.id?.uuid && currentListing?.author?.id?.uuid) {
        try {
          let reviews = await fetchAndLogReviews(id, currentListing.author.id.uuid);
          setReviewsData(reviews);
        } catch (error) {
          console.error('Error fetching reviews:', error);
        }
      }
    };

    fetchReviews(); // Call the async function
  }, [fetchAndLogReviews, currentListing?.author?.id?.uuid]);

  const handleImageLoad = index => {
    setImagesLoaded(prev => ({ ...prev, [index]: true }));
  };
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  /*   const authorName = author.attributes.profile.displayName; */
  const imagesOrder = listing?.attributes?.publicData?.imagesOrder;

  // roomType
  const roomType = props.listing.attributes.publicData.roomtype;
  // function to get roomType
  const getRoomType = type => {
    if (type === 'singlebedroom') {
      return <FormattedMessage id="roomtypes.singlebedroom" />;
    } else if (type === 'doublebedroom') {
      return <FormattedMessage id="roomtypes.doublebedroom" />;
    } else if (type === 'twobedroom') {
      return <FormattedMessage id="roomtypes.twobedroom" />;
    } else if (type === 'shared_bedroom') {
      return <FormattedMessage id="roomtypes.shared_bedroom" />;
    } else if (type === 'multi_bedroom') {
      return <FormattedMessage id="roomtypes.multi_bedroom" />;
    } else if (type === 'entire_accomodation') {
      return <FormattedMessage id="roomtypes.entire_accomodation" />;
    } else if (type === 'tent') {
      return <FormattedMessage id="roomtypes.tent" />;
    } else {
      return <p>not found</p>;
    }
  };
  // title

  const isExperienceType =
    currentListing.attributes?.publicData?.listingType == 'impact-experience';
  const { experienceTitleDe, experienceTitle, stayTitleDe, stayTitle } = publicData;

  const experienceTitleFinal =
    intl.locale.trim() == 'en'
      ? experienceTitle
      : experienceTitleDe?.length > 0
      ? experienceTitleDe
      : experienceTitle;

  const stayTitleMaybe =
    intl.locale.trim() == 'en' ? stayTitle : stayTitleDe?.length > 0 ? stayTitleDe : stayTitle;
  const stayTitleFinal = stayTitleMaybe ? [stayTitleMaybe] : title.split('•');

  

  const titleFirstPart = isExperienceType ? experienceTitleFinal : stayTitleFinal;
  // location & country in german or english
  const language = intl.locale.split('-')[0];

  const currentListingCountryCode = props.listing.attributes.publicData.country;
  const countriesInLanguage = getCountryCodes(language);
  const matchingCountry = countriesInLanguage.find(
    country => country.code === currentListingCountryCode
  );

  const city = props.listing.attributes.publicData.city;

  const categories = props.listing.attributes.publicData?.category?.length
    ? props.listing.attributes.publicData?.category
    : [];

  const firstChosenImageId =
    Array.isArray(imagesOrder) && imagesOrder?.length > 0 && imagesOrder[0]?.id;

  const firstImageBasic =
    currentListing.images && currentListing.images.length > 0
      ? currentListing.images[imageIndex]
      : null;

  const firstImage = firstChosenImageId
    ? currentListing.images && currentListing.images.length > 0
      ? currentListing.images.find(img => img?.id?.uuid === firstChosenImageId) || firstImageBasic
      : firstImageBasic
    : firstImageBasic;

  let max = currentListing.images?.length ? currentListing.images?.length : 1;

  useEffect(() => {
    setCurrentImage(currentListing.images[imageIndex]);
  }, [imageIndex, currentListing.images]);

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const selectedCurrency = publicData?.customCurrency;
  const isUsd = selectedCurrency === 'Dollar';
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl, isUsd);

  let rmvChars = formattedPrice.replace(/[^0-9\.,]/g, '');
  let replaceX = rmvChars.replace(',', '.');
  let rmvPoints = replaceX.replace(/^([^.]*)\.(.*)\./, '$1$2.');
  let roundedPrice = Math.ceil(rmvPoints);
  let finalPrice = `${roundedPrice}€`;
  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;
  const googleReviews = currentListing.attributes.publicData?.googleReviews ?? [];
  const googleRating = currentListing.attributes.publicData?.googleRating ?? 0;

  const getDaysAndNights = isExperienceType
    ? extractDaysAndNights(currentListing?.attributes?.publicData?.MDE)
    : '';
  const translatedMDELabel = isExperienceType ? (
    getDaysAndNights.days == 7 ? (
      <FormattedMessage id="searchPage.experienceLabel1Week" />
    ) : getDaysAndNights.days == 14 ? (
      <FormattedMessage id="searchPage.experienceLabel2Weeks" />
    ) : getDaysAndNights.days == 21 ? (
      <FormattedMessage id="searchPage.experienceLabel3Weeks" />
    ) : (
      <FormattedMessage
        id="listingPage.experiencePackageCardLabel"
        values={{ days: getDaysAndNights.days, nights: getDaysAndNights.nights }}
      />
    )
  ) : (
    ''
  );

  const images = currentListing.images.map(
    image => image.attributes?.variants[currentVariant]?.url
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  const handleTouchStart = e => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = e => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      if (currentIndex < images.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else {
        setCurrentIndex(0);
      }
    } else if (touchStartX - touchEndX < -50) {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      } else {
        setCurrentIndex(images.length - 1);
      }
    }
  };
  const incrementImage = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const decrementImage = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(images.length - 1);
    }
  };
  return (
    <NamedLinkNewTab className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <div
          className={css.slider}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div
            className={css.imageContainer}
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {images.map((image, index) => (
              <div key={index} className={css.imageWrapper}>
                {!imagesLoaded[index] && <Spinner />}
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className={classNames(css.sliderImage, {
                    [css.imageLoaded]: imagesLoaded[index],
                  })}
                  onLoad={() => handleImageLoad(index)}
                  style={{ display: imagesLoaded[index] ? 'block' : 'none' }}
                />
              </div>
            ))}
          </div>
        </div>
        {location.pathname.includes('/favorite') ? (
          <></>
        ) : (
          <div className={css.slider_btns}>
            <div className={css.slider__controls}>
              <button
                className={css.btn}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  decrementImage();
                }}
              >
                <img src={arroLeft} />
              </button>

              <button
                className={css.btn}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  incrementImage();
                }}
              >
                <img src={arroRight} />
              </button>
            </div>
          </div>
        )}
      </AspectRatioWrapper>

      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.roomtypePriceWrapper}>
            <div className={css.roomType}>
              {renderComplete ? (
                Number(googleRating) > 0 ||
                googleReviews?.length ||
                reviewsData?.reviews?.length ||
                currentListing?.attributes?.publicData?.location?.reviews ? (
                  <div className={css.reviewWrapper}>
                    <ReviewRatings
                      currentListing={currentListing}
                      showDot={true}
                      showText={false}
                      isSmall={true}
                      textSize={"13px"}
                      onShowModalFromOutside={() => {}}

                    />
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              <span className={css.roomTypeText}>
                {isExperienceType
                  ? translatedMDELabel
                  : richText(getRoomType(roomType), {
                      longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                      longWordClass: css.longWord,
                    })}
              </span>
            </div>
            <div className={css.roomTypePriceContainer}>
              <div className={css.price}>
                <div className={css.priceValue} title={priceTitle}>
                  <span className={css.priceText}>
                    {isExperienceType ? (
                      <FormattedMessage id="ListingCard.perUnitFrom" className={css.pricLables} />
                    ) : null}
                  </span>
                  {finalPrice}
                  <span className={css.priceText}>
                    {isBookingProcessAlias(publicData?.transactionProcessAlias) ? (
                      isExperienceType ? null : (
                        <FormattedMessage
                          id="ListingCard.perUnit"
                          values={{ unitType: publicData?.unitType }}
                          className={css.pricLables}
                        />
                      )
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={css.titleContainer}>
            <div className={css.title}>
              {isExperienceType
                ? richText(
                    titleFirstPart?.length > 27
                      ? titleFirstPart.slice(0, 27) + '...'
                      : titleFirstPart,
                    {
                      longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                      longWordClass: css.longWord,
                    }
                  )
                : richText(
                    titleFirstPart[0].length > 27
                      ? titleFirstPart[0].slice(0, 27) + '...'
                      : titleFirstPart[0],
                    {
                      longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                      longWordClass: css.longWord,
                    }
                  )}
            </div>
            <div className={css.subtitle}>
              {` ${city}, ${matchingCountry && matchingCountry.name}`}
            </div>
          </div>

          <div className={css.categoriesContainer}>
            <div className={css.categoriesGroup}>
              <div className={css.categories}>
                <div>
                  <img
                    className={css.categoryImg}
                    src={optionImageMap[categories[0]]}
                    alt={categories[0]}
                  />
                </div>
                <div>
                  <p className={css.projectImpact}>
                    {language == 'en'
                      ? isExperienceType
                        ? currentListing?.attributes?.publicData?.ActivityHighlights
                        : 'Supports ' +
                          (currentListing?.author?.attributes?.profile?.publicData?.projectImpact
                            .length > '90'
                            ? currentListing?.author?.attributes?.profile?.publicData?.projectImpact.slice(
                                0,
                                '90'
                              ) + '...'
                            : currentListing?.author?.attributes?.profile?.publicData
                                ?.projectImpact)
                      : isExperienceType
                      ? currentListing?.attributes?.publicData?.ActivityHighlights_de
                      : 'Unterstützt ' +
                        (currentListing?.author?.attributes?.profile?.publicData?.projectImpact_de
                          .length > '90'
                          ? currentListing?.author?.attributes?.profile?.publicData?.projectImpact_de.slice(
                              0,
                              '90'
                            ) + '...'
                          : currentListing?.author?.attributes?.profile?.publicData
                              ?.projectImpact_de)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NamedLinkNewTab>
  );
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: object.isRequired,
  showAuthorInfo: bool,
  renderSizes: string,
  setActiveListing: func,
  fetchAndLogReviews: func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  fetchAndLogReviews: (listingId, hostId) => dispatch(fetchReviews(listingId, hostId, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingCardComponent);
