import React from 'react';

const Newsletter = () => {
  const iframeContent = `
    <html>
      <head>
        <link
          href="https://cdn-images.mailchimp.com/embedcode/classic-061523.css"
          rel="stylesheet"
          type="text/css"
        />
        <style type="text/css">
           #mc_embed_signup {
            background: transparent;
            width: 300px;
            color: white;
            font-family: Helvetica, Arial, sans-serif;
          }
            #mc_embed_signup form {
          width: 300px;
        }

           #mc_embed_signup input {
               width: 290px;
              padding: 0;
              border: 1px solid #ccc;
              border-radius: 4px;
              font-size: 14px;
              line-height: 1.5;
              outline:none;
            }


            #mc_embed_signup #mc-embedded-subscribe {
            background-color: #eb7242;
            color: white;
            border: none;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            transition: background-color 0.2s ease;
            height: 35px;
            width: 290px;
            margin-top: 15px;
            }

            #mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
                        background: transparent;

            }

            #mc-embedded-subscribe-form{
            margin: 0 !important;
            }
          </style>
      </head>
      <body style="margin: 0 !important;">
        <div id="mc_embed_shell">

          <div id="mc_embed_signup">
            <form
              action="https://socialbnb.us22.list-manage.com/subscribe/post?u=e1f3711d4df55918a8ccfec8f&amp;id=37b81d4aef&amp;f_id=0053dbe1f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank"
            >
              <div id="mc_embed_signup_scroll">
                <div class="mc-field-group">
                  <label for="mce-EMAIL">E-Mail <span class="asterisk">*</span></label>
                  <input
                    type="email"
                    name="EMAIL"
                    class="required email"
                    id="mce-EMAIL"
                    required=""
                    value=""
                  />
                </div>
                <div class="mc-field-group">
                  <label for="mce-FNAME">Name <span class="asterisk">*</span></label>
                  <input
                    type="text"
                    name="FNAME"
                    class="required text"
                    id="mce-FNAME"
                    required=""
                    value=""
                  />
                </div>
                <div hidden="">
                  <input type="hidden" name="tags" value="238,1129" />
                </div>

            <div id="mce-responses" class="clear foot">
              <div
                class="response"
                id="mce-error-response"
                style="display: none"
              ></div>
              <div
                class="response"
                id="mce-success-response"
                style="display: none"
              ></div>
            </div>

                <div aria-hidden="true" style="position: absolute; left: -5000px">
                  <input
                    type="text"
                    name="b_e1f3711d4df55918a8ccfec8f_37b81d4aef"
                    tabindex="-1"
                    value=""
                  />
                </div>
                <div class="clear">
                  <input
                    type="submit"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    class="button"
                    value="Subscribe"
                  />
                </div>
              </div>
            </form>
          </div>
          <script
            type="text/javascript"
            src="https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
          ></script>
          <script type="text/javascript">
            (function ($) {
              window.fnames = new Array();
              window.ftypes = new Array();
              fnames[0] = "EMAIL";
              ftypes[0] = "email";
              fnames[1] = "FNAME";
              ftypes[1] = "text";

              $.extend($.validator.messages, {
                required: "Dieses Feld ist ein Pflichtfeld.",
                email: "Geben Sie bitte eine gültige E-Mail Adresse ein.",
              });
            })(jQuery);
            var $mcj = jQuery.noConflict(true);
          </script>
        </div>
      </body>
    </html>
  `;
  return (
    <iframe
      title="Newsletter Subscription"
      srcDoc={iframeContent}
      style={{ width: '371px', height: '350px', border: 'none' }}
    />
  );
};

export default Newsletter;
