import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from '../../../../util/reactIntl';
import css from './ExperienceMockUpSection.module.css';
import BulletPointsFormatter from '../../../../util/formatters/bulletPointsFormatter/bulletPointsFormatter';
import NamedLinkNewTab from '../../../../components/NamedLink/NamedLinkNewTab';

const ExperienceMockUpSection = () => {
  const [userVersion, setUserVersion] = useState(null);
  const intl = useIntl();
  useEffect(() => {
    const itemStr = localStorage.getItem('userVersion');
    if (itemStr) {
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() <= item.expiry) {
        setUserVersion(item.value);
      }
    }
  }, []);
  const textToFormat = intl.formatMessage({ id: 'ListingPage.mockupSection.textB' });
  return (
    <div className={css.section}>
      {userVersion === 'versionA' && (
        <div data-elbaction="visible:vis mockupA">
          <h2>
            <FormattedMessage id="ListingPage.mockupSection.titleA" />
          </h2>
          <FormattedMessage id="ListingPage.mockupSection.textA" />
          <div data-elbaction="click:click btnockupA">
            <NamedLinkNewTab
              name="CMSPage"
              params={{ pageId: 'impact-experiences' }}
              className={css.openBtn}
            >
              <FormattedMessage id="ListingPage.mockupSection.buttonA" />
            </NamedLinkNewTab>
          </div>
        </div>
      )}
      {userVersion === 'versionB' && (
        <div data-elbaction="visible:vis mockupB">
          <h2>
            <FormattedMessage id="ListingPage.mockupSection.titleB" />
          </h2>
          <BulletPointsFormatter text={textToFormat} />
          <div data-elbaction="click:click btnmockupB">
            <NamedLinkNewTab
              name="CMSPage"
              params={{ pageId: 'impact-experiences' }}
              className={css.openBtn}
            >
              <FormattedMessage id="ListingPage.mockupSection.buttonB" />
            </NamedLinkNewTab>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExperienceMockUpSection;
