import React, { useEffect, useState, useRef, useCallback } from 'react';

const SectionLoader = ({ children, skeletonComponent }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const sectionRef = useRef(null);
  const observerRef = useRef(null);

  const checkMobile = useCallback(() => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth <= 768);
    }
  }, []);

  const handleIntersection = useCallback(
    ([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        const delay = isMobile ? 300 : 500;
        setTimeout(() => {
          setIsLoaded(true);
          if (observerRef.current && sectionRef.current) {
            observerRef.current.unobserve(sectionRef.current);
          }
        }, delay);
      }
    },
    [isMobile]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      checkMobile();
      window.addEventListener('resize', checkMobile);
      return () => window.removeEventListener('resize', checkMobile);
    }
  }, [checkMobile]);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.IntersectionObserver) {
      const options = {
        threshold: isMobile ? 0.05 : 0.1,
        rootMargin: isMobile ? '50px' : '0px',
      };

      observerRef.current = new IntersectionObserver(handleIntersection, options);

      if (sectionRef.current) {
        observerRef.current.observe(sectionRef.current);
      }

      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    }
  }, [isMobile, handleIntersection]);

  const skeletonStyles = {
    position: isLoaded ? 'absolute' : 'relative',
    width: '100%',
    opacity: isLoaded ? 0 : 1,
    transition: isMobile ? 'opacity 0.3s ease' : 'opacity 0.5s ease',
    pointerEvents: isLoaded ? 'none' : 'auto',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    willChange: 'opacity',
    transform: 'translateZ(0)',
  };

  const contentStyles = {
    opacity: !isVisible ? 0 : isLoaded ? 1 : 0,
    transition: isMobile ? 'opacity 0.3s ease' : 'opacity 0.5s ease',
    position: 'relative',
    width: '100%',
    zIndex: 0,
    willChange: 'opacity',
    transform: 'translateZ(0)',
  };

  const wrapperStyles = {
    position: 'relative',
    width: '100%',
    backfaceVisibility: 'hidden',
  };

  return (
    <div ref={sectionRef} style={wrapperStyles}>
      {isVisible && !isLoaded && <div style={skeletonStyles}>{skeletonComponent()}</div>}
      <div style={contentStyles}>{children}</div>
    </div>
  );
};

export default SectionLoader;
