import React, { useMemo } from 'react';
import css from './styles/WhySocialbnbSection.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import birdsImage from './assets/birds.svg';
import downArrow from './assets/down-arrow.svg';
import NewsletterForm from './newsletter';
import img1 from './assets/1.webp';
import img2 from './assets/2.webp';
import img3 from './assets/3.webp';
import img4 from './assets/4.webp';

const getReasons = () => [
  {
    id: 1,
    title: <FormattedMessage id="reason1.title" />,
    title2: <FormattedMessage id="reason1.title2" />,
    description: <FormattedMessage id="reason1.description" />,
    icon: img1,
  },
  {
    id: 2,
    title: <FormattedMessage id="reason2.title" />,
    title2: <FormattedMessage id="reason2.title2" />,
    description: <FormattedMessage id="reason2.description" />,
    icon: img2,
  },
  {
    id: 3,
    title: <FormattedMessage id="reason3.title" />,
    description: <FormattedMessage id="reason3.description" />,
    icon: img3,
  },
  {
    id: 4,
    title: <FormattedMessage id="reason4.title" />,
    description: <FormattedMessage id="reason4.description" />,
    icon: img4,
  },
];

const WhySocialbnbSection = () => {
  const intl = useIntl();
  const reasons = useMemo(() => getReasons(), []);

  return (
    <div className={css.outerWrapper}>
      <div className={css.argsWrapper}>
        <div className={css.argsContainer}>
          <div className={css.titleWrapper}>
            <div className={css.titleContent}>
              <h2 className={css.sectionh2Title}>
                <FormattedMessage id="whysocialbnb.title" />
              </h2>
              <div className={css.sectionDescription}>
                <FormattedMessage id="whysocialbnb.description" />
              </div>
            </div>
          </div>
          <div className={css.reasonsWrapper}>
            {reasons.map((reason, i) => (
              <div key={i} className={css.reasonWrapper}>
                <div className={css.reasonId}>{reason.id}</div>
                <div className={css.reasonContent}>
                  <div className={css.reasonTitleWrapper}>
                    {reason.title2 ? (
                      <div className={css.reasonTitleWrapper2}>
                        <span className={css.reasonTitle}>{reason.title}</span>
                        <span className={css.reasonTitle}>{reason.title2}</span>
                      </div>
                    ) : (
                      <span className={css.reasonTitle}>{reason.title}</span>
                    )}
                    <img loading="lazy" src={reason.icon} alt="" />
                  </div>
                  <div className={css.reasonDescription}>{reason.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <img loading="lazy" src={birdsImage} alt="" className={css.birdsImage} />
      </div>
      <div className={css.newsletterWrapper}>
        <div className={css.newsLetterContainer}>
          <div className={css.newsletterContent}>
            <div className={css.newsLetterHeader}>
              <div className={css.newsletterTitle}>
                <FormattedMessage id="whysocialbnb.newslettertitle" />
              </div>
              <div className={css.newsletterDescription}>
                <FormattedMessage id="whysocialbnb.newsletterdescription" />
              </div>
            </div>

            <div className={css.newsletterFormWrapper}>
              <NewsletterForm locale={intl?.locale ?? 'de'} />
            </div>
          </div>
        </div>
        <div className={css.newsletterFooter}>
          <FormattedMessage id="whysocialbnb.newsletterfooter" />
        </div>
        <button className={css.newsletterButton}>
          <img src={downArrow} alt="" className={css.downArrow} />
        </button>
      </div>
    </div>
  );
};

export default WhySocialbnbSection;
