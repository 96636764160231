import React from 'react';
import logo from '../../../OldLogo/logo_weiss_schrift_bild.svg';
import css from './IntroColumn.module.css';
import wsa from '../assets/columns/wsa.webp';
import trustpilot from '../assets/columns/trustpilot.webp';
import winnerAwake from '../assets/columns/winner-awake.webp';
import ExternalLink from '../../../ExternalLink/ExternalLink';
import insta from '../../../../assets/icons/socialmedia/instagram-iconTenerif.png';
import fb from '../../../../assets/icons/socialmedia/facebook-iconTenerif.png';
import linkedIn from '../../../../assets/icons/socialmedia/linkiedin-iconTenerif.png';
import titktok from '../../../../assets/icons/socialmedia/tiktok-icon.png';
import youtube from '../../../../assets/icons/socialmedia/youtube-icon.png';
import trustpilotLogo from '../assets/main/trustpilotLogo.svg';
import trustpilotRating from '../assets/main/trustpilotRating.webp';
import { FormattedMessage } from 'react-intl';

export const IntroColumn = () => {
  return (
    <div className={css.list1}>
      <img src={logo} alt="socialbnb logo" className={css.logo} />
      <div className={css.logos}>
        <a
          href="https://www.trustpilot.com/review/socialbnb.de"
          target="_blank"
          rel="noopener noreferrer"
          className={css.logoLink}
          data-elbaction="click:click intro-trustpilot"
        >
          <div className={css.trustPilot}>
            <img src={trustpilotRating} alt="Trustpilot logo" className={css.trustPilotRating} />
            <span className={css.trustPilotRating}> <FormattedMessage id="footer.truspilotRating"/></span>
            <img src={trustpilotLogo} alt="Trustpilot rating" className={css.trustPilotLogo} />
          </div>
        </a>
        <a
          href="https://www.unwto.org/unwto-awake-tourism-challenge"
          target="_blank"
          rel="noopener noreferrer"
          className={css.logoLink}
          data-elbaction="click:click intro-unwto"
        >
          <img src={winnerAwake} alt="UNWTO Awake Tourism Challenge Winner" className={css.logo} />
        </a>
      </div>
      <div className={css.wsa}>
        <img src={wsa} alt="WSA logo" className={css.logo} data-elbaction="click:click intro-wsa" />
      </div>

      <div
        className={css.footerTop}
      >
        <div className={css.footerTopText}>
          <div className={css.socialMediaBtns}>
            <ExternalLink
              href="https://www.instagram.com/socialbnb/"
              className={css.link}
              data-elbaction="click:click social-instagram"
            >
              <img src={insta} className={css.socialMediaIcon} alt="socialbnb instagram" />
            </ExternalLink>
            <ExternalLink
              href="https://www.facebook.com/Socialbnb"
              className={css.link}
              data-elbaction="click:click social-facebook"
            >
              <img src={fb} className={css.socialMediaIcon} alt="social bnb facebook" />
            </ExternalLink>
            <ExternalLink
              href="https://www.linkedin.com/company/socialbnb/"
              className={css.link}
              data-elbaction="click:click social-linkedin"
            >
              <img src={linkedIn} className={css.socialMediaIcon} alt="social travel linkedin" />
            </ExternalLink>
            <ExternalLink
              href="https://www.tiktok.com/@socialbnb"
              className={css.link}
              data-elbaction="click:click social-tiktok"
            >
              <img src={titktok} className={css.socialMediaIcon} alt="social experience tiktok" />
            </ExternalLink>
          </div>
        </div>
      </div>
    </div>
  );
};
