import { FormattedMessage } from 'react-intl';
import staysImage from '../../../../assets/landingPage/heroSection/dosaguas1.webp';
import foryouImage from '../../../../assets/landingPage/heroSection/foryou.webp';
import experienceImage from '../../../../assets/landingPage/heroSection/kapawi.webp';

export const categoryConfig = {
  stays: {
    heroImage: staysImage,
    heroText: {
      subHeadline: <FormattedMessage id="CustomLandingPage.stays.default.desc" />,
    },
  },
  experiences: {
    heroImage: experienceImage,
    heroText: {
      subHeadline: <FormattedMessage id="CustomLandingPage.experiences.default.desc" />,
    },
  },
  trips: {
    heroImage: staysImage,
    heroText: {
      subHeadline: <FormattedMessage id="heroSectionSubtitleTrips" />,
    },
  },
  forYou: {
    heroImage: foryouImage,
    heroText: {
      subHeadline: <FormattedMessage id="heroSectionSubtitleForYou" />,
    },
  },
};
