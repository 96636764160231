import React, { useEffect, useState } from 'react';
import css from './ExperiencesListingSlider.module.css';
import { FormattedMessage, useIntl } from '../../../../../util/reactIntl';
import arrowLeft from '../../../../../assets/icons/arrows/leftArrowLite.svg';
import arrowRight from '../../../../../assets/icons/arrows/rightArrowLite.png';
import { useHistory } from 'react-router-dom';
import experiencesList from '../../../../../assets/ExperiencelistingSlider/experiencesList.json';
import img1 from '../../../../../assets/ExperiencelistingSlider/1.avif';
import img2 from '../../../../../assets/ExperiencelistingSlider/2.avif';
import img3 from '../../../../../assets/ExperiencelistingSlider/3.avif';
import img4 from '../../../../../assets/ExperiencelistingSlider/4.avif';
import img5 from '../../../../../assets/ExperiencelistingSlider/5.avif';
import img6 from '../../../../../assets/ExperiencelistingSlider/6.avif';
import img7 from '../../../../../assets/ExperiencelistingSlider/7.avif';
import img8 from '../../../../../assets/ExperiencelistingSlider/8.avif';
import img9 from '../../../../../assets/ExperiencelistingSlider/9.avif';
import { PrimaryButton } from '../../../../../components';
import NamedLinkNewTab from '../../../../../components/NamedLink/NamedLinkNewTab';
import ExperiencesListingSlider from '../../../ExperiencesSlider/ExperiencesListingSlider';

const ExperiencesListingSliderListingPage = () => {
  const [suggestedListings, setSuggestedListings] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [index, setIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(0);
  const history = useHistory();
  const intl = useIntl();
  const selectedLanguage = intl.locale?.trim();
  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize < 760) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWidth]);

  useEffect(() => {
    setSuggestedListings(experiencesList);
    setShowSuggestions(true);
  }, []);

  const mod = (n, m) => {
    let result = n % m;
    return result >= 0 ? result : result + m;
  };

  const increaseHandler = () => {
    if (suggestedListings) {
      setIndex((index + 1) % suggestedListings.length);
    }
  };

  const decreaseHandler = () => {
    if (suggestedListings) {
      setIndex(mod(index - 1, suggestedListings.length));
    }
  };

  function truncateString(input) {
    const maxLength = 80;
    if (input.length > maxLength) {
      return input.slice(0, maxLength) + '...';
    } else {
      return input;
    }
  }

  const images = {
    '1': img1,
    '2': img2,
    '3': img3,
    '4': img4,
    '5': img5,
    '6': img6,
    '7': img7,
    '8': img8,
    '9': img9,
  };

  const getCssClass = i => {
    let className = '';
    if (suggestedListings != null) {
      const indexLeft = mod(index - 1, suggestedListings.length);
      const indexRight = mod(index + 1, suggestedListings.length);

      if (isMobile) {
        if (i === indexLeft) {
          className = `${css['mobileSlide']} ${css['mobileSlide-0']}`;
        } else if (i === index) {
          className = ` ${css['mobileSlide-1']}`;
        } else if (i === indexRight) {
          className = `${css['mobileSlide']} ${css['mobileSlide-2']}`;
        } else if (i === (indexRight + 1) % suggestedListings.length) {
          className = `${css['mobileSlide']} ${css['mobileSlide-3']}`;
        } else {
          className = `${css['mobileSlide']} ${css['mobileSlide-3']}`;
        }
      } else {
        if (i === indexLeft) {
          className = `${css['slide']} ${css['slide-0']}`;
        } else if (i === index) {
          className = `${css['slide-1']}`;
        } else if (i === indexRight) {
          className = `${css['slide']} ${css['slide-2']}`;
        } else if (i === (indexRight + 1) % suggestedListings.length) {
          className = `${css['slide']} ${css['slide-3']} `;
        } else if (i === (indexRight + 2) % suggestedListings.length) {
          className = `${css['slide']} ${css['slide-4']} `;
        } else if (i === (indexRight + 3) % suggestedListings.length) {
          className = `${css['slide']} ${css['slide-5']}`;
        } else {
          className = `${css['slide']} ${css['hidden']}`;
        }
      }
    }
    return className;
  };

  const ListingsCards = () => {
    let properListings = [];
    if (suggestedListings && suggestedListings.length > 0) {
      properListings = suggestedListings;
    }
    const spinner = (
      <div className={css.spinnerContainer}>
        <div className={css.spinner}>
          <div className={css.spinnerDot}></div>
          <div className={css.spinnerDot}></div>
          <div className={css.spinnerDot}></div>
        </div>
      </div>
    );
    return (
      <div className={css.mainWrapper}>
        <div className={css.sectionTitleWrapper}>
          <span className={css.sectionTitle}>
            <FormattedMessage id="sectionSuggestedExperienceListings.title" />
          </span>
        </div>

        <div className={css.allCardsWrapper}>
          {suggestedListings?.length > 0 &&
            suggestedListings.map((listing, i) => (
              <div className={getCssClass(i)} key={i}>
                <div className={css.outerWrapper}>
                  <NamedLinkNewTab name={listing?.linkName} params={listing?.linkParams}>
                    <div
                      className={css.innerWrapper}
                      data-elbaction={`click:click suglist-${listing?.titleEN}-uuid-${listing?.id}`}
                    >
                      <div className={css.imageWrapper}>
                        <div className={css.address}>
                          {' '}
                          {selectedLanguage === 'en' ? listing.addressEN : listing.addressDE}{' '}
                        </div>
                        <div className={css.daysLabel}>
                          <div>
                            {' '}
                            {listing.days} <FormattedMessage id="listingPage.experienceDays" />{' '}
                          </div>
                          <div>
                            {' '}
                            {selectedLanguage === 'en' ? listing.titleEN : listing.titleDE}{' '}
                          </div>
                        </div>

                        {listing && listing?.imageLink ? (
                          <img
                            className={css.cardImg}
                            src={images[listing.id]}
                            alt="socialbnb projects "
                          />
                        ) : (
                          spinner
                        )}
                      </div>

                      <div className={css.projectTitleWrapper}>
                        <span className={css.projectName}>
                          {selectedLanguage === 'en'
                            ? listing?.descriptionEN
                            : listing?.descriptionDE}
                        </span>
                        <div className={css.lastSectionWrapper}>
                          <div className={css.discoverBtn}>
                            <FormattedMessage id="listingPage.experienceDiscover" />
                          </div>
                          <div className={css.priceWrapper}>
                            <div className={css.priceText}>
                              <FormattedMessage id="landing.accomodations.from" />
                            </div>
                            <div className={css.price}>{' ' + listing?.price}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NamedLinkNewTab>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  if (!showSuggestions) {
    return (
      <div className={css.discoverContainer}>
        <div className={css.loadingContainer}>
          <div className={css.loading}></div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={css.parent} data-elbaction="visible:vis recommendations">
        <div className={css.parentWrapper}>
          <div className={css.container}>
            <span className={css.sectionTitle}>
              <FormattedMessage id="sectionSuggestedExperienceListings.title" />
            </span>
            <ExperiencesListingSlider />
          </div>
          <div className={css.btnWrapper} data-elbaction="click:click backtosearchpage">
            <PrimaryButton
              className={css.backToSearchBtn}
              type="button"
              onClick={() => history.push(`/${selectedLanguage}/s`)}
            >
              <FormattedMessage id="sectionSuggestedListings.backBtn" />
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
};

export default ExperiencesListingSliderListingPage;
