import React from 'react';
import classNames from 'classnames';

import css from './loadingSpinner.module.css';

const Spinner = ({ className }) => (
  <div className={classNames(className, css.spinner)}>
    <div className={css.spinnerInner}></div>
  </div>
);

export default Spinner;
