import React from 'react';
import css from './ExperienceCard.module.css';
import { FormattedMessage } from 'react-intl';

const ExperienceCard = ({ exp, isVertical, isSmall, currentLanguage }) => {
  return (
    <div>
      {!isSmall ? (
        <a
          href={`/${currentLanguage}${exp.link}`}
          target="_blank"
          rel="noopener noreferrer"
          key={exp.id}
          className={`${css['experience-card']} ${
            isVertical ? css['vertical-view'] : css['horizontal-view']
          }`}
          style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
          data-elb="section"
          data-elb-section={`name:${exp.elwalkerTag};position:${exp.id}`}
          data-elbaction={`click:click card-${exp.elwalkerTag.toLowerCase().replace(/\s+/g, '-')}`}
        >
          <div
            className={`${css['image-container']} ${
              isVertical ? css['vertical-image'] : css['horizontal-image']
            }`}
          >
            <img loading="lazy" src={exp.image} alt={exp.title} />
            <div className={css['image-description']}>
              <span className={css['duration-badge']}>{exp.duration}</span>
              <div className={css['card-title']}>{exp.title}</div>
            </div>
          </div>
          <div className={css['card-content']}>
            <div className={css['card-description']}>{exp.description}</div>
            <div className={css['card-footer']}>
              <button
                className={css['discover-button']}
                data-elbaction={`click:click button-${exp.elwalkerTag
                  .toLowerCase()
                  .replace(/\s+/g, '-')}`}
              >
                <FormattedMessage id="sectionNewExperiences.discoverButton" />
              </button>
              <div className={css['price-container']}>
                <div className={css['price-label']}>
                  <FormattedMessage id="sectionNewExperiences.priceLabel" />
                </div>
                <div className={css['price-value']}>{exp.price}€</div>
              </div>
            </div>
          </div>
        </a>
      ) : (
        <a
          href={exp.link}
          target="_blank"
          rel="noopener noreferrer"
          key={exp.id}
          className={`${css['image-container']} ${
            isVertical ? css['vertical-image-small'] : css['horizontal-image']
          }`}
          style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
          data-elb="section"
          data-elb-section={`name:${exp.elwalkerTag};position:${exp.id}`}
          data-elbaction={`click:click card-${exp.elwalkerTag.toLowerCase().replace(/\s+/g, '-')}`}
        >
          <img loading="lazy" src={exp.image} alt={exp.title} />
          <div className={css['image-description']}>
            <span className={css['duration-badge']}>{exp.duration}</span>
            <div className={css['card-title']}>{exp.title}</div>
          </div>
        </a>
      )}
    </div>
  );
};

export default ExperienceCard;
