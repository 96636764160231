import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './cancellationPolicy.module.css';

const CancellationPolicy = () => {
  return (
    <div className={css.cancellationContainer}>
      <h2 className={css.title}>
        <FormattedMessage id="editlistingPage.cancellationLabel" />
      </h2>
      <div className={css.policyContent}>
        <p className={css.policyItem}>
          <FormattedMessage id="experienceListing.common.cancellationText1" />
        </p>
        <p className={css.policyItem}>
          <FormattedMessage id="experienceListing.common.cancellationText2" />
        </p>
        <p className={css.policyItem}>
          <FormattedMessage id="experienceListing.common.cancellationText3" />
        </p>
        <p className={css.policyItem}>
          <FormattedMessage id="experienceListing.common.cancellationText4" />
        </p>
        <p className={css.policyItem}>
          <FormattedMessage id="experienceListing.common.cancellationText5" />
        </p>
        <p className={css.policyItem}>
          <FormattedMessage id="experienceListing.common.cancellationText6" />
          <a
            href="https://www.socialbnb.org/en/p/agb"
            target="_blank"
            rel="noopener noreferrer"
            className={css.termsLink}
          >
            <FormattedMessage id="experienceListing.common.cancellationText6.link" />
          </a>
        </p>
      </div>
    </div>
  );
};

export default CancellationPolicy;
