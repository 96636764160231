import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './skeleton.css';

const YourMomentSectionSkeleton = () => {
  return (
    <div className="container">
      <Skeleton height={30} width="40%" style={{ marginBottom: '20px' }} />

      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
        <Skeleton height={300} width={230} />
        <Skeleton height={300} width={230} />
        <Skeleton height={300} width={230} />
        <Skeleton height={300} width={230} />
        <Skeleton height={300} width={230} />
      </div>

      <Skeleton height={40} width={150} />
    </div>
  );
};

export default YourMomentSectionSkeleton;
