import React, { useState, useEffect, useRef } from 'react';
import { Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import TestimonialsCard from './TestimonialsCard';
import AccommodationCard from './AccommodationCard';
import QuestionCard from './QuestionCard';
import css from './AmbassadorPage.module.css';
import Footer from '../../components/Footer/FooterNew';
import { LayoutWrapperTopbar, LayoutWrapperFooter, LayoutWrapperMain } from '../../components';
import AmbassadorHeroSection from './AmbassadorHeroSection';
import HowItWorks from './HowItWorks';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

const AmbassadorPage = () => {
  const intl = useIntl();
  const { lang } = useParams();
  const topbarRef = useRef(null);

  const pageContent = (
    <div style={{ overflow: 'hidden', width: '100vw' }}>
      <div className={css.container}>
        <div className={css.heroSectionOuterWrapper}>
          <AmbassadorHeroSection pageLanguage={lang} />
        </div>

        <div className={css.accommodationCardOuterWrapper}>
          <AccommodationCard pageLanguage={lang} />
        </div>

        <div className={css.howitWorksOuterWrapper}>
          <HowItWorks />
        </div>

        <div className={css.testiMonialsOuterWrapper}>
          <div>
            <TestimonialsCard />
          </div>
        </div>

        <div>
          <QuestionCard />
        </div>
      </div>
    </div>
  );
  return (
    <Page
      title={intl.formatMessage({ id: 'AmbassadorPage.title' })}
      description={intl.formatMessage({ id: 'AmbassadorPage.desc' })}
      keywords={intl.formatMessage({ id: 'AmbassadorPage.keywords' })}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'BecomeAmbassador',
        url: `https://socialbnb.org/${intl?.locale || 'en'}/ambassadorPage`,
        name: intl.formatMessage({ id: 'AmbassadorPage.title' }),
      }}
    >
      <LayoutSingleColumn
        mainColumnClassName={css.layoutWrapperMain}
        topbar={
          <div id="topbar" ref={topbarRef} style={{ position: 'fixed', width: '100vw', top: 0 }}>
            <TopbarContainer />
          </div>
        }
        footer={<Footer />}
      >
        <div id="my-scrollbar" className={css.Wrapper}>
          {pageContent}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

export default AmbassadorPage;
