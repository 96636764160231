import spain1 from '../../assets/../../../../assets/impactAccomodationsPics/spanien/1.webp';
import spain2 from '../../assets/../../../../assets/impactAccomodationsPics/spanien/2.webp';
import spain3 from '../../assets/../../../../assets/impactAccomodationsPics/spanien/3.webp';
import spain4 from '../../assets/../../../../assets/impactAccomodationsPics/spanien/4.webp';
import spain5 from '../../assets/../../../../assets/impactAccomodationsPics/spanien/5.webp';
import spain6 from '../../assets/../../../../assets/impactAccomodationsPics/spanien/6.webp';
import spain7 from '../../assets/../../../../assets/impactAccomodationsPics/spanien/7.webp';
import spain8 from '../../assets/../../../../assets/impactAccomodationsPics/spanien/8.webp';
export const spainListings = [
  {
    link: '/l/global-tribe-multi-bedroom/6405e21f-39e0-4971-9acb-9797d9057c78',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'global-tribe-multi-bedroom',
      id: '6405e21f-39e0-4971-9acb-9797d9057c78',
    },
    name: 'Dorm in Eco Camp',
    name_de: 'Schlafsaal im Öko-Camp',
    price: ' 95€',
    location: 'Almonte, Spain',
    projectType: ['nature'],
    image: spain1,
    finalReviews: '4.7',
    totalFinalReviews: 4.7,
  },
  {
    link: '/l/enriquezarte-shared-bedroom/606c6f7d-012d-41cb-bd14-5faa5c89739c',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'enriquezarte-shared-bedroom',
      id: '606c6f7d-012d-41cb-bd14-5faa5c89739c',
    },
    name: 'Shared Apartment in Barcelona',
    name_de: 'Geteiltes Apartment in Barcelona',
    price: ' 59€',
    location: 'Barcelona, Spain',
    projectType: ['equality','education'],
    image: spain2,
    finalReviews: '4.7',
    totalFinalReviews: 4.7,
  },
  {
    link: '/l/guatazales-entire-accomodation/637b84b3-24f6-457d-b369-795fb9f9206c',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'guatazales-entire-accomodation',
      id: '637b84b3-24f6-457d-b369-795fb9f9206c',
    },
    name: 'Apartment in Eco Farms',
    name_de: 'Ferienwohnung in Öko-Farmen',
    price: ' 415€',
    location: 'Murcia, Spain',
    projectType: ['nature', 'education'],
    image: spain3,
    finalReviews: '5',
    totalFinalReviews: 5,
  },
  {
    link: '/l/casa-pousadoira-doublebedroom/6260fe27-cd80-42e8-8f46-7924f7ec555a',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'casa-pousadoira-doublebedroom',
      id: '6260fe27-cd80-42e8-8f46-7924f7ec555a',
    },
    name: 'Double Room in Eco Agriturismo',
    name_de: 'Doppelzimmer im Öko-Agriturismo',
    price: ' 69€',
    location: 'A Coruña, Spain',
    projectType: ['nature', 'education'],
    image: spain4,
    finalReviews: '4.6',
    totalFinalReviews: 4.6,
  },
  {
    link: '/l/permakultur-camp-shared-bedroom/6390d65a-3f01-4752-b05b-f52df834a112',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'permakultur-camp-shared-bedroom',
      id: '6390d65a-3f01-4752-b05b-f52df834a112',
    },
    name: 'Permaculture Camp',
    name_de: 'Permakultur Camp',
    price: ' 36€',
    location: 'Granadilla de Abona, Spain',
    projectType: ['nature', 'sports'],
    image: spain5,
    finalReviews: '4.9',
    totalFinalReviews: 5,
  },
  {
    link: '/l/acougo-de-pitasana-doublebedroom/65284e68-a0d7-4079-9ebd-51445e20744e',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'acougo-de-pitasana-doublebedroom',
      id: '65284e68-a0d7-4079-9ebd-51445e20744e',
    },
    name: 'Family Room in Social Hotel',
    name_de: 'Familienzimmer im sozialen Hotel',
    price: ' 50€',
    location: 'Boal , Spain',
    projectType: ['nature', 'animal'],
    image: spain6,
    finalReviews: '5',
    totalFinalReviews: 0,
  },
  {
    link: '/l/lurreko-arom-ticas-y-biodiversidad-tent/63092f17-0285-4456-8c0c-40acc84e8efe',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'lurreko-arom-ticas-y-biodiversidad-tent',
      id: '63092f17-0285-4456-8c0c-40acc84e8efe',
    },
    name: 'Camping on Permaculture Farm',
    name_de: 'Camping auf Permakultur-Farm',
    price: ' 20€',
    location: 'Préjano, Spain',
    projectType: ['nature', 'education'],
    image: spain7,
    finalReviews: '5',
    totalFinalReviews: 5,
  },
  {
    link: '/l/serenity-aguatavar-singlebedroom/63dfaddd-f783-42aa-ab8e-80c851756e39',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'serenity-aguatavar-singlebedroom',
      id: '63dfaddd-f783-42aa-ab8e-80c851756e39',
    },
    name: 'Traditional Stone House',
    name_de: 'Traditionelles Steinhaus',
    price: ' 21€',
    location: 'Tijarafe, Spain',
    projectType: ['nature', 'health'],
    image: spain8,
    finalReviews: '0',
    totalFinalReviews: 0,
  },
];
