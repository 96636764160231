import React, { useState, useEffect } from 'react';
import css from './SectionImpactAccomodations.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { categoryDefault } from './impactAccomodationsList.enum';
import CategoryButtons from './CategoryButtons';
import Pin_Icon from '../../../../assets/Pin_Icon.svg';
import animal from '../../../../assets/categories/animal.webp';
import education from '../../../../assets/categories/education.webp';
import nature from '../../../../assets/categories/environment.webp';
import equality from '../../../../assets/categories/equality.webp';
import health from '../../../../assets/categories/health.webp';
import sports from '../../../../assets/categories/sports.webp';
import NamedLinkNewTab from '../../../../components/NamedLink/NamedLinkNewTab';
import IconReviewSearchPage from '../../../ListingPage/ListingPageComponents/ReviewRatings/IconReviewSearchPage/iconReviewSearchPage';

const ImpactAccomodations = ({ subHeader }) => {
  const [selectedCategory, setSelectedCategory] = useState(categoryDefault);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isMiddleDevice, setIsMiddleDevice] = useState(false);
  
  const intl = useIntl();
  const currentLanguage = intl.locale.split('-')[0]; 

  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      setIsMobileDevice(screenSize < 1025);
      setIsMiddleDevice(screenSize < 1400);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to get the displayed name
  const getDisplayedName = item => {
    const nameToUse = currentLanguage === 'de' && item?.name_de ? item?.name_de : item?.name; 
    return nameToUse.split(' • ')[0] ?? nameToUse;
  };

  const showCategoryElements = array => {
    const maxItems = isMobileDevice ? 4 : isMiddleDevice ? 9 : 8;
    return array.slice(0, maxItems).map((item, i) => {
      const shortName = getDisplayedName(item);
      const truncatedName = shortName.length < 24 ? shortName : shortName.slice(0, 24) + '...';

      return (
        <NamedLinkNewTab key={i} name={item?.linkName} params={item?.linkParams}>
          <div
            className={css.oneCard}
            data-elbaction={`click:click lp-impct-list-${shortName}-uuid-${item?.link.substring(
              item?.link.lastIndexOf('/') + 1
            )};`}
          >
            <img className={css.img} src={item?.image} alt="socialbnb impact accommodation" />
            <div className={css.spanDescription}>
              <div className={css.listDescriptionWrapper}>
                <p className={css.descriptionTxt}>
                  <span className={css.name}>{truncatedName}</span>
                  <span className={css.priceSubtitle}>
                    <FormattedMessage id="landing.accomodations.from" />
                    <span className={css.price}>{item?.price}</span>
                    <FormattedMessage id="landing.accomodations.pernight" />
                  </span>
                </p>
              </div>
              <div className={css.pinlogo}>
                <div>
                  <img
                    src={Pin_Icon}
                    width="18px"
                    style={{ paddingRight: '2px' }}
                    alt="socialbnb impact icon"
                  />
                  <span className={css.location}>{item?.location}</span>
                </div>
                {item.finalReviews > 0 ? (
                  <div className={css.projectRatings}>
                    <IconReviewSearchPage
                      averageRating={item.finalReviews}
                      showDot={false}
                      textColor={"black"}
                    />
                  </div>
                ) : (
                  <div className={css.emptySpacer}></div>
                )}
              </div>
              <div className={css.projectype}>
                <div className={css.projectImg}>
                  <span className={css.projectImg1}>
                    {getIconByProjecttype(item?.projectType[0])}
                  </span>
                  <span className={css.projectImg2}>
                    {getIconByProjecttype(item?.projectType[1])}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </NamedLinkNewTab>
      );
    });
  };

  const getIconByProjecttype = projectT => {
    switch (projectT) {
      case 'animal':
        return <img src={animal} style={{ height: '27px', width: '27px' }} alt="animal" />;
      case 'education':
        return <img src={education} style={{ height: '27px', width: '27px' }} alt="education" />;
      case 'nature':
        return <img src={nature} style={{ height: '27px', width: '27px' }} alt="nature" />;
      case 'equality':
        return <img src={equality} style={{ height: '27px', width: '27px' }} alt="equality" />;
      case 'health':
        return <img src={health} style={{ height: '27px', width: '27px' }} alt="health" />;
      case 'sports':
        return <img src={sports} style={{ height: '27px', width: '27px' }} alt="sports" />;
      default:
        return '';
    }
  };

  return (
    <div className={css.outerWrapper}>
      <div className={css.container}>
        <h2 className={css.sectionTitle}>{subHeader}</h2>
        <CategoryButtons
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          isMobileDevice={isMobileDevice}
        />
        <div className={css.AllCards}>{showCategoryElements(selectedCategory)}</div>
      </div>
    </div>
  );
};

export default ImpactAccomodations;
