import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, ...rest } = props;

  return (
    <svg
      className={className}
      {...rest}
      width="21"
      height="25"
      viewBox="0 0 21 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="logo_grau_tuerkis_schrift_bild"
          transform="translate(0.000000, -27.000000)"
          fill="#1C7881"
          fillRule="nonzero"
        >
          <g id="Combined-Shape" transform="translate(0.000000, 27.000000)">
            <path d="M2.59362844e-15,5.81590909 C-0.0148687584,4.46284245 0.522913497,3.1598988 1.49406836,2.19606692 C2.46522323,1.23223503 3.78947132,0.687188955 5.17309859,0.681818182 C6.57644121,0.669108028 7.92717151,1.20353477 8.92669659,2.16695784 C9.92622167,3.13038091 10.492222,4.44345413 10.4995775,5.81590909 C10.4995775,9.57272727 7.43894366,12.4840909 5.96091549,14.5431818 C5.7835528,14.7915456 5.49332004,14.9395222 5.18355634,14.9395222 C4.87379264,14.9395222 4.58355987,14.7915456 4.40619718,14.5431818 C2.96302817,12.4704545 2.59362844e-15,9.54545455 2.59362844e-15,5.81590909 Z M5.15915493,9.40909091 C7.20758685,9.40909091 8.86816901,7.78510559 8.86816901,5.78181818 C8.86816901,3.77853077 7.20758685,2.15454545 5.15915493,2.15454545 C3.11072301,2.15454545 1.45014085,3.77853077 1.45014085,5.78181818 C1.45014085,7.78510559 3.11072301,9.40909091 5.15915493,9.40909091 Z M5.20795775,8.03863636 L4.61535211,7.5 L3.74387324,6.77045455 C3.422876,6.49347224 3.20673043,6.11874955 3.13035211,5.70681818 C3.06416944,5.46100739 3.06416944,5.20262897 3.13035211,4.95681818 C3.25037769,4.59595343 3.56365443,4.32891516 3.94558461,4.26191092 C4.3275148,4.19490668 4.71616967,4.338801 4.95697183,4.63636364 C5.01971831,4.70454545 5.08246479,4.78636364 5.13126761,4.84772727 C5.23762229,4.72067725 5.35170191,4.60000441 5.47288732,4.48636364 C5.76037301,4.22918749 6.17603875,4.1623281 6.53260563,4.31590909 C6.89491029,4.44113937 7.15038681,4.76009194 7.18795775,5.13409091 C7.28787976,5.71902087 7.07480778,6.31327888 6.62323944,6.70909091 C6.27464789,7.04318182 5.92605634,7.39090909 5.56352113,7.69772727 L5.20795775,8.03863636 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
