import React, { useEffect, useState } from 'react';
import css from './ThisIsSocialbnb.module.css';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import largeScreenImageDE from './images/large-screen-de.png';
import largeScreenImageEN from './images/large-screen-en.png';
import smallScreenImageDE from './images/small-screen-de.png';
import smallScreenImageEN from './images/small-screen-en.png';

const ThisIsSocialbnb = () => {
  const { lang } = useParams();
  const [image, setImage] = useState(largeScreenImageEN);

  useEffect(() => {
    const updateImage = () => {
      const isLargeScreen = window.innerWidth >= 768;
      if (isLargeScreen) {
        setImage(lang === 'de' ? largeScreenImageDE : largeScreenImageEN);
      } else {
        setImage(lang === 'de' ? smallScreenImageDE : smallScreenImageEN);
      }
    };

    updateImage();
    window.addEventListener('resize', updateImage);

    return () => {
      window.removeEventListener('resize', updateImage);
    };
  }, [lang]);

  return (
    <div className={css.container}>
      <h2 className={css.title}>
        <FormattedMessage id="landing.what.is.socialbnb.title" />
      </h2>

      <div className={css.imageContainer}>
        <img alt="thisissocialbnb-image" src={image} className={css.responsiveImage} />
      </div>
    </div>
  );
};

export default ThisIsSocialbnb;
