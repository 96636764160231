import React, { useState, useMemo, useCallback } from 'react';
import css from './ExperiencesListingSlider.module.css';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import Swipe from 'react-easy-swipe';
import experiencesList from '../../../assets/ExperiencelistingSlider/experiencesList.json';
import { useParams } from 'react-router-dom';
import IconReviewSearchPage from '../ListingPageComponents/ReviewRatings/IconReviewSearchPage/iconReviewSearchPage';

const importAll = r =>
  r.keys().reduce((acc, item) => ({ ...acc, [item.replace('./', '')]: r(item) }), {});
const images = importAll(
  require.context('../../../assets/ExperiencelistingSlider', false, /\.(png|jpe?g|svg|avif)$/)
);
const arrows = importAll(
  require.context('../../../assets/icons/arrows', false, /\.(png|jpe?g|svg)$/)
);

const ExperiencesListingSlider = ({ subHeader }) => {
  const intl = useIntl();
  const { lang } = useParams();
  const [state, setState] = useState({ index: 0, slideData: experiencesList });

  const updateIndex = useCallback(
    delta =>
      setState(prev => ({
        ...prev,
        index: (prev.index + delta + prev.slideData.length) % prev.slideData.length,
      })),
    []
  );

  const handlers = useMemo(
    () => ({
      increase: () => updateIndex(1),
      decrease: () => updateIndex(-1),
      swipeLeft: () => updateIndex(1),
      swipeRight: () => updateIndex(-1),
    }),
    [updateIndex]
  );

  const getClassName = useCallback(
    i => {
      const { index, slideData } = state;
      const positionMap = {
        [(index - 1 + slideData.length) % slideData.length]: 0,
        [index]: 1,
        [(index + 1) % slideData.length]: 2,
        [(index + 2) % slideData.length]: 3,
        [(index + 3) % slideData.length]: 4,
        [(index + 4) % slideData.length]: 5,
      };
      return positionMap.hasOwnProperty(i)
        ? `${css['slide']} ${css[`slide-${positionMap[i]}`]}`
        : css['hidden'];
    },
    [state]
  );

  const renderSlide = useCallback(
    (
      {
        id,
        link,
        addressEN,
        addressDE,
        days,
        titleEN,
        titleDE,
        descriptionEN,
        descriptionDE,
        price,
        ratings,
        totalReviews,
      },
      i
    ) => {
      return (
        <>
          <div className={getClassName(i)} key={id}>
            <div className={css.outerWrapper}>
              <a href={`/${lang || 'en'}${link}`} target="_blank" rel="noopener noreferrer">
                <div className={css.innerWrapper} data-elbaction={`click:click suglist-${titleEN}`}>
                  <div className={css.imageWrapper}>
                    <div className={css.address}>
                      {intl.locale?.trim() === 'en' ? addressEN : addressDE}{' '}
                    </div>
                    <div className={css.daysLabel}>
                      <div>{intl.locale?.trim() === 'en' ? titleEN : titleDE}</div>
                    </div>
                    {ratings && ratings !== 0 ? (
                      <div className={css.ratings}>
                        <IconReviewSearchPage
                          averageRating={ratings}
                          showDot={true}
                          textColor={'white'}
                          value={ratings}
                          forRoomType={true}
                          isWhite={true}
                          isLandingPage={true}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <img
                      className={css.cardImg}
                      src={images[`${id}.avif`]}
                      alt="socialbnb projects"
                    />
                  </div>
                  <div className={css.projectTitleWrapper}>
                    <div className={css.priceWrapper}>
                      <div className={css.priceText}>
                        {days + ' '}
                        {days == 1 ? (
                          <FormattedMessage id="listingPage.experienceOneDay" />
                        ) : (
                          <FormattedMessage id="listingPage.experienceDays" />
                        )}{' '}
                        <FormattedMessage id="landing.accomodations.fromSmall" />
                      </div>
                      <div className={css.price}>{' ' + price}</div>
                    </div>
                    <span className={css.projectName}>
                      {intl.locale?.trim() === 'en' ? descriptionEN : descriptionDE}
                    </span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </>
      );
    },
    [getClassName, intl.locale]
  );

  return (
    <>
      <div className={css.sliderHeader}>
        <h2 className={css.sectionh2}>{subHeader ?? ''}</h2>
        <p className={css.sliderSubtitle}>
          <FormattedMessage id="CustomLandingPage.experiences.default.p" />
        </p>
      </div>
      <div className={css['slider-container']}>
        <div className={css['slider-buttons']}>
          {['leftArrowLite.svg', 'rightArrowLite.png'].map((arrow, i) => (
            <img
              key={arrow}
              onClick={i === 0 ? handlers.decrease : handlers.increase}
              className={css['slider-button']}
              src={arrows[arrow]}
              alt={`arrow${i === 0 ? 'Left' : 'Right'} - socialbnb`}
            />
          ))}
        </div>
        <div className={css['slider-cards_title']}>
          <Swipe
            onSwipeLeft={handlers.swipeLeft}
            onSwipeRight={handlers.swipeRight}
            allowMouseEvents
            tolerance={50}
            delta={10}
            preventdefaulttouchmoveevent="false"
          >
            <div className={css['slider-cards']}>{state.slideData.map(renderSlide)}</div>
          </Swipe>
        </div>
      </div>
    </>
  );
};

export default ExperiencesListingSlider;
