import React, { useState } from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';
import css from './OnSite.module.css';
import HouseRules from './HouseRules';
import Languages from './Languages';
import PackList from './PackList';
import Modal from '../Utils/Modal/Modal';

const OnSite = ({ publicData }) => {
  const [selectedTitle, setSelectedTitle] = useState('languages');
  const [showFullText, setShowFullText] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
    setIsModalOpen(!isModalOpen);
  };
  const handleTitleClick = title => {
    setSelectedTitle(title);
  };

  const houseRulesEn = publicData && publicData.houserules ? publicData.houserules : [];
  const houseRulesDe = publicData && publicData.houserules_de ? publicData.houserules_de : [];
  const shortHouseRulesEn = typeof houseRulesEn === 'string' ? houseRulesEn.substring(0, 350) : '';
  const shortHouseRulesDe = typeof houseRulesDe === 'string' ? houseRulesDe.substring(0, 350) : '';
  const languages = publicData && publicData.languages ? publicData.languages : [];
  const otherLanguagesEn = publicData && publicData.otherLanguages ? publicData.otherLanguages : [];
  const otherLanguagesDe =
    publicData && publicData.otherLanguages_de ? publicData.otherLanguages_de : [];
  const packList = publicData && publicData.packlist ? true : false;
  const packListEn = publicData && publicData.packlist ? publicData.packlist : [];
  const packListDe = publicData && publicData.packlist_de ? publicData.packlist_de : [];
  const shortPackListEn = typeof packListEn === 'string' ? packListEn.substring(0, 350) : '';
  const shortPackListDe = typeof packListDe === 'string' ? packListDe.substring(0, 350) : '';

  return (
    <div className={css.onSiteMain} data-elbaction="visible:vis onsite">
      <h2>
        <FormattedMessage id="ListingPage.onsite" />
      </h2>
      <div className={css.onSiteHeadings}>
        <a
          href="#languages"
          className={`${css.onSiteTitle} ${selectedTitle === 'languages' ? css.active : ''}`}
          onClick={() => handleTitleClick('languages')}
          data-elbaction="click:click languages"
        >
          <FormattedMessage id="EditListingDescriptionForm.languages" />
        </a>
        <a
          href="#packList"
          className={`${css.onSiteTitle} ${selectedTitle === 'packList' ? css.active : ''}`}
          onClick={() => handleTitleClick('packList')}
          data-elbaction="click:click packaginglist"
        >
          <FormattedMessage id="EditListingDescriptionForm.packlist" />
        </a>
        <a
          href="#houserules"
          className={`${css.onSiteTitle} ${selectedTitle === 'houserules' ? css.active : ''}`}
          onClick={() => handleTitleClick('houserules')}
          data-elbaction="click:click houserules"
        >
          <FormattedMessage id="EditListingDescriptionForm.houserules" />
        </a>
      </div>

      {selectedTitle && (
        <div className={css.selectedContent}>
          {selectedTitle === 'languages' &&
            (languages.length === 0 ? (
              '  N/A'
            ) : (
              <Languages
                languages={languages}
                otherLanguagesEn={otherLanguagesEn}
                otherLanguagesDe={otherLanguagesDe}
              />
            ))}
          {selectedTitle === 'packList' &&
            (packListEn.length === 0 ? (
              '  N/A'
            ) : packListEn.length > 200 ? (
              showFullText ? (
                <>
                  {isModalOpen && (
                    <Modal isOpen={isModalOpen} onClose={toggleShowFullText}>
                      <h2>
                        <FormattedMessage id="EditListingDescriptionForm.packlist" />
                      </h2>
                      <p className={css.ModalText}>
                        <PackList
                          packList={packList}
                          packListEn={packListEn}
                          packListDe={packListDe}
                        />
                      </p>
                    </Modal>
                  )}{' '}
                </>
              ) : (
                <>
                  <div className={css.shortenedText}>
                    <PackList
                      packList={packList}
                      packListEn={shortPackListEn}
                      packListDe={shortPackListDe}
                    />
                  </div>
                  <button className={css.btn} onClick={toggleShowFullText}>
                    <p className={css.btnText}>
                      {' '}
                      <FormattedMessage id="EditListingOffersForm.activities.showMore" />
                    </p>
                    <p className={css.Gt}>&gt;</p>
                  </button>
                </>
              )
            ) : (
              <PackList packList={packList} packListEn={packListEn} packListDe={packListDe} />
            ))}
          {selectedTitle === 'houserules' &&
            (houseRulesEn.length === 0 ? (
              '  N/A'
            ) : houseRulesEn.length > 400 ? (
              showFullText ? (
                <>
                  {isModalOpen && (
                    <Modal isOpen={isModalOpen} onClose={toggleShowFullText}>
                      <h2>
                        <FormattedMessage id="EditListingDescriptionForm.houserules" />
                      </h2>
                      <p className={css.ModalText}>
                        <HouseRules houseRules={houseRulesEn} houseRulesDe={houseRulesDe} />
                      </p>
                    </Modal>
                  )}{' '}
                </>
              ) : (
                <>
                  <div className={css.shortenedText}>
                    <HouseRules houseRules={shortHouseRulesEn} houseRulesDe={shortHouseRulesDe} />
                  </div>
                  <button className={css.btn} onClick={toggleShowFullText}>
                    <p className={css.btnText}>
                      {' '}
                      <FormattedMessage id="EditListingOffersForm.activities.showMore" />
                    </p>
                    <p className={css.Gt}>&gt;</p>
                  </button>
                </>
              )
            ) : (
              <HouseRules houseRules={houseRulesEn} houseRulesDe={houseRulesDe} />
            ))}
        </div>
      )}
    </div>
  );
};

export default OnSite;
