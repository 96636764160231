import React, { useState } from 'react';
import css from './QuickMenu.module.css';
import imgAlex from './images/01_Alex_orange.webp';
import { FormattedMessage } from 'react-intl';
import Phone from './icons/Phone';
import Xmark from './icons/Xmark';
import Email from './icons/Email';
import WhatsApp from './icons/Whatsapp';

const QuickMenu = () => {
  const [isSmallContact, setIsSmallContact] = useState(true);

  const whatsappNumber = '+4915730977660';
  const whatsappLink = `https://wa.me/${whatsappNumber}`;
  return (
    <div className={css.container}>
      {isSmallContact ? (
        <div className={css.buttonContainer}>
          <button className={css.button} onClick={() => setIsSmallContact(false)}>
            <div>
              <div>
                <img src={imgAlex} className={css.imageButton} alt="socialbnb memeber alex alt"/>
              </div>
              <div className={css.phoneIconContainer}>
                <Phone />
              </div>
            </div>
          </button>
        </div>
      ) : (
        <div className={css.quickMenuFullContainer}>
          <button className={css.closeButton} onClick={() => setIsSmallContact(true)}>
            <Xmark />
          </button>
          <div>
            <div>
              <img
                src={imgAlex}
                alt="socialbnb memeber alex alt"
                width="100px"
                className={css.image}
              />
              <div>
                <p className={css.text}>
                  <FormattedMessage id="landingPage.quikMenu.text1" />
                  <FormattedMessage id="landingPage.quikMenu.text2" />
                </p>
                <p className={css.text}>

                </p>
                <a href="tel:+49 157 3097 76 60" className={css.phoneNumberHref}>
                  +49 157 3097 76 60
                </a>
              </div>

              <div className={css.phoneEmailContainer}>
                <a href="mailto:booking@socialbnb.de">
                  <Email />
                </a>
                <a href={whatsappLink}>
                  <WhatsApp />
                </a>
                <a href="tel:+49 157 3097 76 60">
                  <Phone width={28} height={28} />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuickMenu;
