import React from 'react';

const WhatsApp = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 13.7 2.43 15.3 3.18 16.7L2 22L7.3 20.82C8.7 21.57 10.3 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
        stroke="#FFFFFF"
        fill="none"
      />
      <path
        d="M17.6 14.25C17.4 14.05 16.62 13.35 15.88 12.7C15.14 12.05 14.89 11.95 14.69 12.15C14.49 12.35 14.19 12.85 13.99 13.15C13.79 13.45 13.49 13.5 13.19 13.3C12.89 13.1 12.09 12.82 11.19 12.02C10.49 11.42 9.99 10.67 9.79 10.37C9.59 10.07 9.76 9.87 9.96 9.67C10.16 9.47 10.36 9.17 10.56 8.97C10.76 8.77 10.86 8.47 10.96 8.27C11.06 8.07 11.01 7.77 10.91 7.57C10.81 7.37 10.31 6.57 10.01 6.07C9.71 5.57 9.41 5.67 9.21 5.67C9.01 5.67 8.71 5.67 8.41 5.67C8.11 5.67 7.66 5.97 7.41 6.27C7.16 6.57 6.36 7.27 6.36 8.07C6.36 8.87 6.96 9.67 7.06 9.87C7.16 10.07 9.29 13.35 12.49 14.75C13.29 15.05 13.92 15.25 14.42 15.35C15.22 15.55 15.92 15.55 16.52 15.45C17.12 15.35 17.8 14.65 18 14.15C18.2 13.65 18.2 13.15 18.1 13.05C18 12.95 17.8 12.85 17.6 12.75V14.25Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default WhatsApp;
