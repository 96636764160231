import React, { useState } from 'react';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routing/routeConfiguration';
import { useConfiguration } from '../../../../context/configurationContext';
import css from '../../navbar.module.css';
import TopbarSearchForm from '../../../Topbar/TopbarSearchForm/TopbarSearchForm';
import SearchIcon from '../../../Topbar/SearchIcon';
import { useIntl } from 'react-intl';

export const TopBarSearch = props => {
  const { currentSearchParams, history, location, lang } = props;
  const [locationInput, setLocationInput] = useState('');
  const config = useConfiguration();
  const langFromIntl = useIntl().locale.trim();

  const handleSubmit = values => {
    const keywords = values.keywords;
    const search = values?.location?.search ? values?.location?.search : '';
    const selectedPlace = values?.location?.selectedPlace ? values?.location?.selectedPlace : '';
    const { bounds, country, origin } = selectedPlace;
    const currentSearchParams = { bounds, origin, country };

    const searchParams = {
      keywords,
      ...currentSearchParams,
      origin,
      address: search,
      country,
      bounds,
    };

    if (search !== '' || selectedPlace !== '') {
      history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {
            langFromIntl,
          },
          searchParams
        )
      );
    } else {
      history.push(`/${langFromIntl}/s`);
    }
  };

  const handleLocationChange = v => {
    setLocationInput(v);
  };
  if (location.pathname.startsWith('/en/s') || location.pathname.startsWith('/de/s')) {
    return null;
  } else {
    return (
      <div className={css.searchBar}>
        <SearchIcon className={css.searchIcon} />
        <TopbarSearchForm
          onSubmit={handleSubmit}
          initialSearchFormValues
          isMobile={false}
          handleLocationChange={handleLocationChange}
          isTobarSearch={true}
        />
      </div>
    );
  }
};
