import React, { useEffect, useSt, useState } from 'react';
import { FormattedMessage } from '../../../../../util/reactIntl';

import css from './IconReviewStarMini.module.css';

const IconReviewStarMini = props => {
  const {
    value,
    reviewsNumber,
    classNameContainer,
    onShowModalFromOutside,
    isSmall,
    showText,
    showDot = true,
    textSize = '1rem',
  } = props;
  const [filledPercentage, setFilledPercentage] = useState(0);

  useEffect(() => {
    let finalRate = Number((Number(value) / 5) * 100);
    setFilledPercentage(finalRate);
  }, [value]);

  const formattedNumber = value.toString().replace('.', ',');

  const containerClassName = `${css.starContainer} ${classNameContainer}`;

  const handleOnShowModalFromOutside = () => {
    onShowModalFromOutside(true);
  };

  const isClickable = reviewsNumber !== 0;
  const reviewHeadingClassName = isClickable ? css.reviewsHeading : css.reviewsHeadingUnclickable;
  return (
    <div className={containerClassName}>
      {filledPercentage !== 0 ? (
        <svg
          className={css.star}
          width={!isSmall ? '22' : '18'}
          height={!isSmall ? '22' : '18'}
          viewBox="0 0 22 22"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            zIndex: 1,
            position: !isSmall ? 'relative' : 'unset',
            top: !isSmall ? '0.25em' : '0px',
          }}
        >
          <defs>
            <linearGradient id="starFillGradient">
              <stop offset="0%" stopColor="#1C7881" />
              <stop offset={`${filledPercentage}%`} stopColor="#1C7881" />
              <stop offset={`${filledPercentage}%`} stopColor="#FFF" />
              <stop offset="100%" stopColor="#FFF" />
            </linearGradient>
          </defs>
          <path
            d="M10 16.7111L16.18 20.7931L14.54 13.0997L20 7.92327L12.81 7.2557L10 0L7.19 7.2557L0 7.92327L5.46 13.0997L3.82 20.7931L10 16.7111Z"
            fill="url(#starFillGradient)"
            className={isClickable ? css.starPath : css.starPathUnclickable}
          />
        </svg>
      ) : (
        <div className={css.noRatingStar}>
          <svg
            className={css.star}
            width={!isSmall ? '30' : '20'}
            height={!isSmall ? '30' : '20'}
            viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              zIndex: 1,
              position: !isSmall ? 'relative' : 'unset',
              top: !isSmall ? '0.25em' : 'unset',
            }}
          >
            <defs>
              <linearGradient id="starFillGradient2">
                <stop offset="0%" stopColor="#1C7881" />
                <stop offset={`${0}%`} stopColor="#1C7881" />
                <stop offset={`${0}%`} stopColor="#FFF" />
                <stop offset="100%" stopColor="#FFF" />
              </linearGradient>
            </defs>
            <path
              d="M10 16.7111L16.18 20.7931L14.54 13.0997L20 7.92327L12.81 7.2557L10 0L7.19 7.2557L0 7.92327L5.46 13.0997L3.82 20.7931L10 16.7111Z"
              fill="url(#starFillGradient)"
              className={isClickable ? css.starPath : css.starPathUnclickable}
            />
          </svg>
        </div>
      )}
      {formattedNumber != 0 && formattedNumber !== null && formattedNumber !== undefined ? (
        <p className={css.starReviews} style={{ fontSize: textSize }}>
          {formattedNumber}
        </p>
      ) : (
        <p
          className={isClickable ? css.starReviews : css.reviewsNumberUnclickable}
          style={{ fontSize: textSize }}
        >
          {formattedNumber}
        </p>
      )}
      {showDot ? (
        <span className={isClickable ? css.starReviews : css.reviewsNumberUnclickable}>
          <span className={!isSmall ? css.blackpointHostModal : null}>&bull;</span>
        </span>
      ) : (
        <> </>
      )}
      {showText ? (
        <p className={reviewHeadingClassName} onClick={handleOnShowModalFromOutside}>
          {' '}
          {reviewsNumber != 1 ? (
            <FormattedMessage id="ListingPage.reviewsHeading" values={{ reviewsNumber }} />
          ) : (
            <FormattedMessage id="ListingPage.reviewsHeadingOne" values={{ reviewsNumber }} />
          )}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default IconReviewStarMini;
