import colombia1 from '../../assets/../../../../assets/impactAccomodationsPics/kolumbien/1.webp';
import colombia2 from '../../assets/../../../../assets/impactAccomodationsPics/kolumbien/2.webp';
import colombia3 from '../../assets/../../../../assets/impactAccomodationsPics/kolumbien/3.webp';
import colombia4 from '../../assets/../../../../assets/impactAccomodationsPics/kolumbien/4.webp';
import colombia5 from '../../assets/../../../../assets/impactAccomodationsPics/kolumbien/5.webp';
import colombia6 from '../../assets/../../../../assets/impactAccomodationsPics/kolumbien/6.webp';
import colombia7 from '../../assets/../../../../assets/impactAccomodationsPics/kolumbien/7.webp';
import colombia8 from '../../assets/../../../../assets/impactAccomodationsPics/kolumbien/8.webp';

export const colombiaListings = [
  {
    link: '/l/dos-aguas-double-with-balcony-doublebedroom/65a6fd8a-68c5-41bb-bc71-bccdd0716988',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'dos-aguas-double-with-balcony-doublebedroom',
      id: '65a6fd8a-68c5-41bb-bc71-bccdd0716988',
    },
    name: 'Double room in Eco Hostel',
    name_de: 'Doppelzimmer in Eco Hostel',
    price: ' 118€',
    location: 'Rincon del Mar, Colombia',
    projectType: ['nature', 'equality'],
    image: colombia1,
    finalReviews: '4.8',
    totalFinalReviews: 5,
  },
  {
    link: '/l/finca-la-selvita-doublebedroom/64d56d78-7c8e-4b2e-9398-a90e6edb40be',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'finca-la-selvita-doublebedroom',
      id: '64d56d78-7c8e-4b2e-9398-a90e6edb40be',
    },
    name: 'Treehouse on Coffeefarm',
    name_de: 'Baumhaus auf Kaffeefarm',
    price: ' 64€',
    location: 'Santa Marta, Colombia',
    projectType: ['nature'],
    image: colombia2,
    finalReviews: '5',
    totalFinalReviews: 2,
  },
  {
    link: '/l/reserva-biologica-caoba-doublebedroom/62337420-e9af-43f0-98d3-8efd7edbe433',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'reserva-biologica-caoba-doublebedroom',
      id: '62337420-e9af-43f0-98d3-8efd7edbe433',
    },
    name: 'Casa Jardin Jungle',
    name_de: 'Casa Jardin Dschungel',
    price: ' 58€',
    location: 'Santa Marta, Colombia',
    projectType: ['nature', 'animal'],
    image: colombia3,
    finalReviews: '4.8',
    totalFinalReviews: 4.8,
  },
  {
    link: '/l/fundaci-n-pijao-citt-slow-doublebedroom/62824e8f-1520-4b76-8ebf-0b7acd0833be',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'fundaci-n-pijao-citt-slow-doublebedroom',
      id: '62824e8f-1520-4b76-8ebf-0b7acd0833be',
    },
    name: 'Private Room Colombia',
    name_de: 'Privatzimmer Kolumbien',
    price: ' 55€',
    location: 'Pijao, Colombia',
    projectType: ['nature', 'education'],
    image: colombia4,
    finalReviews: '5',
    totalFinalReviews: 5,
  },
  {
    link: '/l/hotel-yay-sustainable-doublebedroom/67432667-fcb4-4241-b7fb-bc4f6d2d8ef3',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'hotel-yay-sustainable-doublebedroom',
      id: '67432667-fcb4-4241-b7fb-bc4f6d2d8ef3',
    },
    name: 'Kogui House Santa Marta',
    name_de: 'Kogui-Haus Santa Marta',
    price: ' 97€',
    location: 'Santa Marta, Colombia',
    projectType: ['nature', 'health'],
    image: colombia5,
    finalReviews: '4.7',
    totalFinalReviews: 4.7,
  },
  {
    link:
      '/l/maloka-napu-ecodestinos-turismo-de-naturaleza-doublebedroom/6617f9c0-afe0-4339-a61f-0d980ed77117',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'maloka-napu-ecodestinos-turismo-de-naturaleza-doublebedroom',
      id: '6617f9c0-afe0-4339-a61f-0d980ed77117',
    },
    name: 'Eco Lodge Amazon',
    name_de: 'Öko-Lodge Amazonas',
    price: ' 97€',
    location: 'Santa Marta, Colombia',
    projectType: ['nature', 'health'],
    image: colombia6,
    finalReviews: '4.7',
    totalFinalReviews: 4.7,
  },
  {
    link: '/l/reserva-natural-xieti-entire-accomodation/60fe3a9d-e47f-44e9-aeb4-90981cef49cb',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'reserva-natural-xieti-entire-accomodation',
      id: '60fe3a9d-e47f-44e9-aeb4-90981cef49cb',
    },
    name: 'Reserva Natural Xieti',
    name_de: 'Reserva Natural Xieti',
    price: ' 65€',
    location: 'Tota, Colombia',
    projectType: ['nature', 'education'],
    image: colombia7,
    finalReviews: '4.9',
    totalFinalReviews: 4.9,
  },
  {
    link: '/l/selvatorium-doublebedroom/65ef2abf-5528-477c-88d9-4aa618a54e8a',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'selvatorium-doublebedroom',
      id: '65ef2abf-5528-477c-88d9-4aa618a54e8a',
    },
    name: 'Kogi Bungalow',
    name_de: 'Kogi-Bungalow',
    price: ' 23€',
    location: 'Palomino, Colombia',
    projectType: ['nature', 'education'],
    image: colombia8,
    finalReviews: '5',
    totalFinalReviews: 1,
  },
];
