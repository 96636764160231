import namibia1 from '../../assets/../../../../assets/impactAccomodationsPics/namibia/1.webp';
import namibia2 from '../../assets/../../../../assets/impactAccomodationsPics/namibia/2.webp';
import namibia3 from '../../assets/../../../../assets/impactAccomodationsPics/namibia/3.webp';
import namibia4 from '../../assets/../../../../assets/impactAccomodationsPics/namibia/4.webp';
import namibia5 from '../../assets/../../../../assets/impactAccomodationsPics/namibia/5.webp';
import namibia6 from '../../assets/../../../../assets/impactAccomodationsPics/namibia/6.webp';
import namibia7 from '../../assets/../../../../assets/impactAccomodationsPics/namibia/7.webp';
import namibia8 from '../../assets/../../../../assets/impactAccomodationsPics/namibia/8.webp';

export const namibiaListings = [
  {
    link:
      '/l/kuzikus-rhino-wildlife-reserve-lodge-singlebedroom/640ece10-c9fe-4722-8c2b-1f5063f8e766',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'kuzikus-rhino-wildlife-reserve-lodge-singlebedroom',
      id: '640ece10-c9fe-4722-8c2b-1f5063f8e766',
    },
    name: 'Namibia Wildlife Lodge',
    name_de: 'Namibia Wildlife Lodge',
    price: ' 162€',
    location: 'Central Namibia, Namibia',
    projectType: ['nature', 'animal'],
    image: namibia1,
    finalReviews: '4.8',
    totalFinalReviews: 5,
  },
  {
    link: '/l/penduka-trust-doublebedroom/60e462cb-1cd7-469a-b724-8c7ad5713ad5',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'penduka-trust-doublebedroom',
      id: '60e462cb-1cd7-469a-b724-8c7ad5713ad5',
    },
    name: 'Penduka Trust',
    name_de: 'Penduka Trust',
    price: ' 56€',
    location: 'Windhuk, Namibia',
    projectType: ['nature', 'education'],
    image: namibia2,
    finalReviews: '5',
    totalFinalReviews: 5,
  },
  {
    link: '/l/steps-for-children-doublebedroom/62c842b0-66d4-4981-811b-44a25a3107cf',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'steps-for-children-doublebedroom',
      id: '62c842b0-66d4-4981-811b-44a25a3107cf',
    },
    name: 'Dome Guesthouse',
    name_de: 'Gästehaus Kuppeldach',
    price: ' 25€',
    location: 'Gobabis, Namibia',
    projectType: ['nature', 'equality'],
    image: namibia3,
    finalReviews: '0',
    totalFinalReviews: 0,
  },
  {
    link: '/l/hobatere-lodge-twobedroom/62a1ff2e-ba58-4f87-bdc6-c3b3b3b04055',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'hobatere-lodge-twobedroom',
      id: '62a1ff2e-ba58-4f87-bdc6-c3b3b3b04055',
    },
    name: 'Lux. Twin Room Eco',
    name_de: 'Lux. Zweibettzimmer',
    price: ' 266€',
    location: 'Kamanjab, Namibia',
    projectType: ['nature', 'animal'],
    image: namibia4,
    finalReviews: '4.4',
    totalFinalReviews: 4.4,
  },
  {
    link: '/l/grootberg-lodge-doublebedroom/62a0ce34-b8bf-4f85-9365-58bff1a12257',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'grootberg-lodge-doublebedroom',
      id: '62a0ce34-b8bf-4f85-9365-58bff1a12257',
    },
    name: 'Luxury Lodge Hôas',
    name_de: 'Luxus Lodge Hôas',
    price: ' 403€',
    location: 'Palmwag, Namibia',
    projectType: ['nature', 'animal'],
    image: namibia5,
    finalReviews: '4.6',
    totalFinalReviews: 4.6,
  },
  {
    link: '/l/hoada-campsite-tent/62a1d5b5-b9a8-4374-ae55-a69589aa1f61',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'hoada-campsite-tent',
      id: '62a1d5b5-b9a8-4374-ae55-a69589aa1f61',
    },
    name: 'Glamping Tent Namibia',
    name_de: 'Glamping-Zelt Namibia',
    price: ' 21€',
    location: 'Kamanjab, Namibia',
    projectType: ['nature', 'animal'],
    image: namibia6,
    finalReviews: '0',
    totalFinalReviews: 0,
  },
  {
    link: '/l/tsaurab-valley-eco-camp-tent/66740023-f55d-4328-8dbe-db50bb199dd6',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'tsaurab-valley-eco-camp-tent',
      id: '66740023-f55d-4328-8dbe-db50bb199dd6',
    },
    name: 'Eco Camp Desert',
    name_de: 'Öko-Camp Wüste',
    price: ' 23€',
    location: 'Uis, Namibia',
    projectType: ['nature', 'education'],
    image: namibia7,
    finalReviews: '0',
    totalFinalReviews: 0,
  },
  {
    link: '/l/steps-for-children-singlebedroom/642c5223-51c6-45a6-8b6a-9626e67cd86d',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'steps-for-children-singlebedroom',
      id: '642c5223-51c6-45a6-8b6a-9626e67cd86d',
    },
    name: 'Single Room Hostel',
    name_de: 'Einzelzimmer Hostel',
    price: ' 27€',
    location: 'Okakarara, Namibia',
    projectType: ['nature', 'education'],
    image: namibia8,
    finalReviews: '0',
    totalFinalReviews: 0,
  },
];
