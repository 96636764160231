import React, { useEffect, useRef, useState } from 'react';
import css from './ListingVideo.module.css';
import { FormattedMessage } from '../../../../util/reactIntl';
import ReactPlayer from 'react-player';

const ListingVideo = props => {
  const [isMobile, setIsMobile] = useState(false);
  const [setWidth] = useState(0);
  const videoPlayerRef = useRef(null);
  let videoLink = null;
  if (props) {
    videoLink = props.currentListing.attributes.publicData.youtubeLink;
  }
  const isExperienceType =
    props.currentListing.attributes?.publicData?.listingType == 'impact-experience';
  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize < 576) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWidth]);

  const triggerDataElbAction = () => {
    const divElement = videoPlayerRef.current;
    if (divElement) {
      const event = new MouseEvent('click', { bubbles: true });
      divElement.dispatchEvent(event);
    }
  };
  return (
    <>
      {videoLink ? (
        <div className={css.videoSectionWrapper}>
          <h2>
            {isExperienceType ? (
              <FormattedMessage id="listingPage.youtubeExperienceLabel" />
            ) : (
              <FormattedMessage id="listingPage.videoSection.title" />
            )}
          </h2>

          <div
            className={css.videoPlayer}
            data-elbaction="click:click ytvideostart"
            ref={videoPlayerRef}
          >
            {/* <div></div> */}
            <ReactPlayer
              controls={true}
              width={isMobile ? '100%' : '100%'}
              height={isMobile ? '202px' : '609px'}
              url={videoLink}
              onPlay={triggerDataElbAction}
            />
            {/* <div></div> */}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ListingVideo;
