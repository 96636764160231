import React, { useEffect, useState } from 'react';
import LogoImage from './assets/logo_final.svg';

const SplashScreen = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth <= 768);
    }
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        transform: 'translateZ(0)',
        willChange: 'transform',
      }}
    >
      <img
        src={LogoImage}
        alt="logo"
        style={{
          width: isMobile ? '100px' : '150px',
          height: isMobile ? '200px' : '300px',
          objectFit: 'contain',
          transform: 'translateZ(0)',
        }}
        loading="eager"
      />
    </div>
  );
};

export default SplashScreen;
