import React from 'react';
import css from './iconReviewSearchPage.module.css';

const IconReviewSearchPage = ({
  averageRating,
  classNameContainer,
  showDot,
  textColor, // white or black or null
}) => {
  const formattedNumber = averageRating?.toString().replace('.', ',');
  const containerClassName = `${css.starContainer} ${css[classNameContainer]}`;
  const starFillColor = averageRating > 0.1 ? '#1C7881' : '#FFF';

  const getTextClassName = () => {
    switch (textColor) {
      case 'white':
        return css.whiteText;
      case 'black':
        return css.blackText;
      default:
        return css.starReviews;
    }
  };

  if (averageRating == 0 || averageRating === null || averageRating === undefined) {
    return <></>;
  }
  return (
    <div className={containerClassName}>
      <svg
        className={css.star}
        width="14"
        height="14"
        viewBox="0 0 22 22"
        xmlns="http://www.w3.org/2000/svg"
        style={{ zIndex: 2 }}
      >
        <path
          d="M10 16.7111L16.18 20.7931L14.54 13.0997L20 7.92327L12.81 7.2557L10 0L7.19 7.2557L0 7.92327L5.46 13.0997L3.82 20.7931L10 16.7111Z"
          fill={starFillColor}
          className={textColor === 'white' ? css.whiteStroke : css.starPath}
        />
      </svg>

      <p className={getTextClassName()}>{formattedNumber}</p>
      {showDot && <span className={css.starReviews}>&bull;</span>}
    </div>
  );
};

export default IconReviewSearchPage;
