import React, { useEffect, useState, useMemo } from 'react';
import css from './FooterNew.module.css';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { useLocation } from 'react-router-dom';
import NamedLinkNewTab from '../NamedLink/NamedLinkNewTab';
import { FirstColumn } from './components/FooterBottomLists/FirstColumn';
import { SecondColumn } from './components/FooterBottomLists/SecondColumn';
import { ThirdColumn } from './components/FooterBottomLists/ThirdColumn';
import { IntroColumn } from './components/FooterBottomLists/IntroColumn';
import { FooterHeader } from './components/Header';
import { FooterMain } from './components/Main';
import LanguageSwitcherWide from '../LanguageSwitcherMobile/LanguageSwitcherWide';

const downloadLinks = {
  safetyInfoEN: 'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/safetyInfoEN.pdf',
  safetyInfoDE: 'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/safetyInfoDE.pdf',
  childProtectionPolicy:
    'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/ChildProtectionPolicy.pdf',
  KinderschutzPolicy:
    'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/KinderschutzPolicy.pdf',
  qualityCriteriaDE:
    'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/qualityCriteriaDE.pdf',
  qualityCriteriaEN:
    'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/qualityCriteriaEN.pdf',
  travelerGuidlines:
    'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/TravelerGuidelines.pdf',
  reisendeGuidlines:
    'https://6725180.fs1.hubspotusercontent-na1.net/hubfs/6725180/GuidelineFuerReisende.pdf',
  file2022de: 'https://drive.google.com/uc?export=download&id=1uLYtbR0XRa25XjPH3BU7Iw3kjf_wS1QU',
  file2022en: 'https://drive.google.com/uc?export=download&id=1gnQX3QFcWdZMrtgcdNiTiOnb2Zrams-P',
};

// Cache allowed paths
const allowedPaths = ['/en', '/de', '/de/', '/en/', '/'];

const Footer = () => {
  const intl = useIntl();
  const selectedLanguage = intl.locale;
  const [displayAdditionalSections, setDisplayAdditionalSections] = useState(false);
  const location = useLocation();

  const currentYear = useMemo(() => new Date().getFullYear(), []);

  const scrollToTop = () => {
    if (typeof window !== 'undefined' && window.document) {
      document.body.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (location) {
      const shouldDisplay = allowedPaths.includes(location?.pathname.trim());
      setDisplayAdditionalSections(shouldDisplay);
    }
  }, [location]);

  const copyrightText = useMemo(
    () => (
      <div className={css.copyright}>
        Copyright <span>&copy;</span> {currentYear} socialbnb
      </div>
    ),
    [currentYear]
  );

  return (
    <div
      className={css.footerContainer}
      data-elb="section"
      data-elb-section="name:footerMain;position:13"
      data-elbaction="visible:section lp-footer visible"
    >
      {displayAdditionalSections && (
        <div data-elbaction="visible:vis footer-search&supported">
          <FooterHeader />
          <FooterMain />
        </div>
      )}

      <div
        className={css.footerBottom}
        data-elb="section"
        data-elb-section="name:footerBottom;position:14"
        data-elbaction="visible:section lp-footer bottom"
      >
        <div className={css.bottomSectionWrapper}>
          <div className={css.footerBottomContainer}>
            <div>
              <IntroColumn />
            </div>
            <div>
              <FirstColumn scrollToTop={scrollToTop} css={css} />
            </div>
            <div className={css.mobileColumn}>
              <SecondColumn
                selectedLanguage={selectedLanguage}
                css={css}
                downloadLinks={downloadLinks}
              />
            </div>
            <div className={css.mobileColumn}>
              <ThirdColumn css={css} />
            </div>
          </div>
        </div>
        <div className={css.bottomSection}>
          <div
            className={css.languageSwitcher}
            data-elbaction="click:click section click language-switcher"
          >
            <LanguageSwitcherWide />
          </div>
          <div className={css.spacer} />
          <div className={css.tocBottomContainer}>
            <NamedLinkNewTab
              name="CMSPage"
              params={{ pageId: 'impressum' }}
              className={css.link}
              data-elbaction="click:click section click footer-imprint"
            >
              <span className={css.linkText}>
                <FormattedMessage id="Footer.imprint" />
              </span>
            </NamedLinkNewTab>
            <NamedLinkNewTab
              name="CMSPage"
              params={{ pageId: 'agb' }}
              className={css.link}
              data-elbaction="click:click section click footer-terms"
            >
              <span className={css.linkText}>
                <FormattedMessage id="Footer.termsOfUse" />
              </span>
            </NamedLinkNewTab>
            <NamedLinkNewTab
              name="CMSPage"
              params={{ pageId: 'datenschutz' }}
              className={css.link}
              data-elbaction="click:click section click footer-privacy"
            >
              <span className={css.linkText}>
                <FormattedMessage id="Footer.privacyPolicy" />
              </span>
            </NamedLinkNewTab>
          </div>
          <div className={css.spacer} />
          <div className={css.copyrightContainer}>{copyrightText}</div>
        </div>
        <div
          className={css.bottomSectionMobile}
          data-elb="section"
          data-elb-section="name:footerMobileLinks;position:16"
          data-elbaction="visible:section vis lp-feat-section"
        >
          <div className={css.mobileLinksContainer}>
            <div
              className={css.languageSwitcher}
              data-elbaction="click:click section click footer-language-switcher"
            >
              <LanguageSwitcherWide />
            </div>
            <div className={css.spacer} />
            <NamedLinkNewTab
              name="CMSPage"
              params={{ pageId: 'impressum' }}
              className={css.link}
              data-elbaction="click:click section click footer-imprint"
            >
              <span className={css.linkText}>
                <FormattedMessage id="Footer.imprint" />
              </span>
            </NamedLinkNewTab>
            <NamedLinkNewTab
              name="CMSPage"
              params={{ pageId: 'agb' }}
              className={css.link}
              data-elbaction="click:click section click footer-mobile-terms"
            >
              <span className={css.linkText}>
                <FormattedMessage id="Footer.termsOfUse" />
              </span>
            </NamedLinkNewTab>
            <NamedLinkNewTab
              name="CMSPage"
              params={{ pageId: 'datenschutz' }}
              className={css.link}
              data-elbaction="click:click section click footer-privacy"
            >
              <span className={css.linkText}>
                <FormattedMessage id="Footer.privacyPolicy" />
              </span>
            </NamedLinkNewTab>
          </div>
          <div className={css.copyrightContainer}>{copyrightText}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
