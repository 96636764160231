import indonesien1 from '../../assets/../../../../assets/impactAccomodationsPics/indonesien/1.webp';
import indonesien2 from '../../assets/../../../../assets/impactAccomodationsPics/indonesien/2.webp';
import indonesien3 from '../../assets/../../../../assets/impactAccomodationsPics/indonesien/3.webp';
import indonesien4 from '../../assets/../../../../assets/impactAccomodationsPics/indonesien/4.webp';
import indonesien5 from '../../assets/../../../../assets/impactAccomodationsPics/indonesien/5.webp';
import indonesien6 from '../../assets/../../../../assets/impactAccomodationsPics/indonesien/6.webp';
import indonesien7 from '../../assets/../../../../assets/impactAccomodationsPics/indonesien/7.webp';
import indonesien8 from '../../assets/../../../../assets/impactAccomodationsPics/indonesien/8.webp';

export const indonesiaListings = [
  {
    link: '/l/mama-s-homestay-doublebedroom/66ed642a-6aa7-437b-94eb-405f1747042b',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'mama-s-homestay-doublebedroom',
      id: '66ed642a-6aa7-437b-94eb-405f1747042b',
    },
    name: 'Family Homestay',
    name_de: 'Familien-Homestay',
    price: ' 20€',
    location: 'Ruteng, Indonesia',
    projectType: ['nature', 'education'],
    image: indonesien1,
    finalReviews: '4.8',
    totalFinalReviews: 33,
  },
  {
    link: '/l/astungkara-way-shared-bedroom/65fc1944-9b63-4eff-818c-e5bec37f0bec',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'astungkara-way-shared-bedroom',
      id: '65fc1944-9b63-4eff-818c-e5bec37f0bec',
    },
    name: 'Treehouse Bali',
    name_de: 'Baumhaus Bali',
    price: ' 70€',
    location: 'Sibangkaja, Indonesia',
    projectType: ['nature', 'health'],
    image: indonesien2,
    finalReviews: '0',
    totalFinalReviews: 0,
  },
  {
    link: '/l/ubuntu-bali-entire-accomodation/633d6100-ca35-428a-81e9-1f89dd128aa6',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ubuntu-bali-entire-accomodation',
      id: '633d6100-ca35-428a-81e9-1f89dd128aa6',
    },
    name: 'Deluxe Bungalow Yoga',
    name_de: 'Deluxe-Bungalow Yoga',
    price: ' 50€',
    location: 'Canggu, Indonesia',
    projectType: ['nature', 'education'],
    image: indonesien3,
    finalReviews: '4.8',
    totalFinalReviews: 4.8,
  },
  {
    link: '/l/ibu-bumi-orangutan-doublebedroom/63baab8a-16a4-4ba4-820a-f3e1aad15299',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ibu-bumi-orangutan-doublebedroom',
      id: '63baab8a-16a4-4ba4-820a-f3e1aad15299',
    },
    name: 'Orangutan Science station',
    name_de: 'Orang-Utan Forschungsstation',
    price: ' 25€',
    location: 'Batu Katak, Indonesia',
    projectType: ['nature', 'education'],
    image: indonesien4,
    finalReviews: '5',
    totalFinalReviews: 5,
  },
  {
    link: '/l/serenity-eco-guesthouse-and-yoga-doublebedroom/62c39f3d-014d-4bba-94fd-c9ccd251a3ac',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'serenity-eco-guesthouse-and-yoga-doublebedroom',
      id: '62c39f3d-014d-4bba-94fd-c9ccd251a3ac',
    },
    name: 'Eco Guesthouse Canggu',
    name_de: 'Öko-Gästehaus Canggu',
    price: ' 62€',
    location: 'Denpasar, Indonesia',
    projectType: ['nature', 'health'],
    image: indonesien5,
    finalReviews: '4.7',
    totalFinalReviews: 4.7,
  },
  {
    link: '/l/ibu-bumi-orangutan-shared-bedroom/63c02289-830e-4058-971e-7dec423998e5',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'ibu-bumi-orangutan-shared-bedroom',
      id: '63c02289-830e-4058-971e-7dec423998e5',
    },
    name: 'Orangutan Science station',
    name_de: 'Orang-Utan Forschungsstation',
    price: ' 17€',
    location: 'timban lawang, Indonesia',
    projectType: ['nature', 'education'],
    image: indonesien6,
    finalReviews: '0',
    totalFinalReviews: 0,
  },
  {
    link: '/l/blue-lagoon-eco-village-entire-accomodation/6319a89b-d28b-4e3e-9c8e-3a3cf34554dd',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'blue-lagoon-eco-village-entire-accomodation',
      id: '6319a89b-d28b-4e3e-9c8e-3a3cf34554dd',
    },
    name: 'Villa in Eco Village',
    name_de: 'Villa im Ökodorf Bali',
    price: ' 121€',
    location: 'Karangasem, Indonesia',
    projectType: ['nature', 'animal'],
    image: indonesien7,
    finalReviews: '4.6',
    totalFinalReviews: 4.6,
  },
  {
    link: '/l/project-wings-twobedroom/62deafa0-5661-4bb7-a058-ea7c1166cf3b',
    linkName: 'ListingPage',
    linkParams: {
      slug: 'project-wings-twobedroom',
      id: '62deafa0-5661-4bb7-a058-ea7c1166cf3b',
    },
    name: 'Recycling Village',
    name_de: 'Recycling-Dorf',
    price: ' 11€',
    location: 'Bukit Lawang, Indonesia',
    projectType: ['nature', 'education'],
    image: indonesien8,
    finalReviews: '4.7',
    totalFinalReviews: 4.7,
  },
];
